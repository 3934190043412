import { Col, Row, Select, Table, Tag } from 'antd';
import ReusableModal from '../Atoms/Modal';
import PropTypes from 'prop-types';
import { useState } from 'react';
import Boton from '../Atoms/Boton';
import parseDate from '../../Utils/parseDate';
import { CloseCircleOutlined, FileDoneOutlined } from '@ant-design/icons';
import moment from 'moment';
import decodeToken from '../../Utils/decodeToken';

const HistoryCheckList = ({ dataCheckList }) => {
  const [ismodalOpenHistoryChechk, setIsModalOpenHistoryChechk] = useState(false);
  const [descriptionCheckList, setDescriptionCheckList] = useState('');
  const [scrollY, setScrollY] = useState(0);
  let decodedToken = decodeToken(localStorage.getItem('token'));
  let userDeparment = decodedToken?.user?.display_name_department;
  let userArea = decodedToken?.user?.display_name_area;
  let userRole = decodedToken?.role?.name;

  const handleOkHistoryCheck = () => {
    setIsModalOpenHistoryChechk(true);
    setScrollY(window.scrollY);
    document.body.classList.add('body-fixed');
  };

  const handleCancelHistoryChech = () => {
    setIsModalOpenHistoryChechk(false);
    setDescriptionCheckList('');
    document.body.classList.remove('body-fixed');
    window.scrollTo(0, scrollY);
  };

  const columnsHistoryCheck = [
    {
      title: 'REFERENCIA',
      dataIndex: 'reference',
      key: 'reference',
      fixed: 'left'
    },
    {
      title: 'ESTADO',
      dataIndex: 'status',
      key: 'status',
      width: 95
    },
    {
      title: 'FECHA DE ACTUALIZACIÓN',
      dataIndex: 'date',
      key: 'date'
    }
  ];

  let filterData = [];

  if (dataCheckList !== undefined && dataCheckList !== null) {
    const groupData = dataCheckList?.reduce((acc, item) => {
      acc[item?.description] = acc[item?.description] || [];
      acc[item?.description].push(item);
      return acc;
    }, {});

    filterData = Object.keys(groupData).map((key) => {
      const dates = groupData[key].map((item) => new Date(item?.created_at));
      const mostRecentDate = new Date(Math.max.apply(null, dates));
      return groupData[key].find(
        (item) => new Date(item?.created_at).getTime() === mostRecentDate.getTime()
      );
    });
  }

  let mostrarDescripcion = dataCheckList?.filter(
    (item) => item?.description === descriptionCheckList
  );

  let mostarData = descriptionCheckList === '' ? filterData : mostrarDescripcion.reverse();

  const data = mostarData?.map((item) => {
    return {
      key: item?.id,
      reference: item?.description,
      status: (
        <>
          {item?.status_checklist === 'Creado' ? (
            <Tag color="blue">{item?.status_checklist}</Tag>
          ) : item?.status_checklist === 'Pendiente' ? (
            <Tag color="orange">{item?.status_checklist}</Tag>
          ) : item?.status_checklist === 'Listo' ? (
            <Tag color="green">{item?.status_checklist}</Tag>
          ) : (
            <Tag color="red">{item?.status_checklist}</Tag>
          )}
        </>
      ),
      date: (
        <>
          <Row>
            <Col xs={24} md={12}>
              {parseDate(item?.created_at, 'short')}
            </Col>
            <Col xs={24} md={12}>
              {moment(item?.created_at).format('LT  ')}
            </Col>
          </Row>
        </>
      )
    };
  });

  return (
    <>
      {decodedToken?.sub === 280 ||
      userDeparment === 'ventas' ||
      userArea === 'mesa_de_control' ||
      userArea === 'sgcmc' ||
      userDeparment === 'direccion' ||
      userRole === 'Super_Admin' ? (
        <div className="mb-3 flex justify-content-end">
          <Boton type="secondary" onClick={handleOkHistoryCheck} icon={<FileDoneOutlined />}>
            Historial CheckList
          </Boton>
        </div>
      ) : null}

      <ReusableModal
        title="HISTORIAL CHECKLIST"
        className="mt-2 mb-2"
        open={ismodalOpenHistoryChechk}
        onCancel={handleCancelHistoryChech}
        width={700}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        okText="Actualizar">
        <div className="mb-2 flex justify-content-center">
          <strong className="mr-2 mt-1">Filtrar: </strong>
          <Select
            onChange={(value) => setDescriptionCheckList(value)}
            className="mr-1"
            value={descriptionCheckList}
            style={{
              width: 200
            }}
            options={[
              {
                value: 'OC',
                label: 'OC'
              },
              {
                value: 'Virtual',
                label: 'Virtual'
              },
              {
                value: 'Logo',
                label: 'Logo'
              },
              {
                value: 'AI',
                label: 'AI'
              },
              {
                value: 'Cotización proveedor',
                label: 'Cotización poveedor'
              },
              {
                value: 'Distribución',
                label: 'Distribución'
              },
              {
                value: 'Contacto',
                label: 'Contacto'
              },
              {
                value: 'Datos de facturación',
                label: 'Datos de facturación'
              },
              {
                value: 'Dirección de entrega',
                label: 'Dirección de entrega'
              }
            ]}
          />

          {descriptionCheckList !== '' ? (
            <Boton
              className="ml-2"
              style={{
                border: 'none',
                height: '20px',
                boxShadow: 'none',
                padding: '0px ',
                color: 'var(--secondary-color-red)'
              }}
              onClick={() => setDescriptionCheckList('')}>
              <CloseCircleOutlined />
            </Boton>
          ) : null}
        </div>

        <Table
          pagination={false}
          columns={columnsHistoryCheck}
          dataSource={data}
          scroll={{ y: 460, x: 500 }}
        />
      </ReusableModal>
    </>
  );
};

HistoryCheckList.propTypes = {
  dataCheckList: PropTypes.array
};

export default HistoryCheckList;
