// const apiEndpoint = 'https://test-backendbpms.promolife.lat/api';

// const photosEndpoint = process.env.REACT_APP_PHOTOS_ENDPOINT;

// console.log(process.env.NODE_ENV);
// console.log('despliegue');
// console.log(process.env.REACT_APP_PHOTOS_ENDPOINT);
// console.log(process.env.REACT_APP_API_ENDPOINT);

let apiEndpoint, photosEndpoint;

if (process.env.NODE_ENV === 'development') {
  apiEndpoint = 'https://dev-api-bpms.promolife.lat/api';
  photosEndpoint = 'https://dev-api-bpms.promolife.lat/';
  // apiEndpoint = 'http://127.0.0.1:8000/api';
  // photosEndpoint = 'https://dev-api-bpms.promolife.lat/';
  // console.log('apiEndpoint', apiEndpoint);
  // console.log(photosEndpoint);
} else {
  apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
  photosEndpoint = process.env.REACT_APP_PHOTOS_ENDPOINT;
  console.log(apiEndpoint);
  console.log(photosEndpoint);
}

export { apiEndpoint, photosEndpoint };
