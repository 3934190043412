import React from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';

const ReusableModal = ({
  title,
  className,
  onOk,
  footer,
  children,
  open,
  okText,
  onCancel,
  cancelText,
  okButtonProps,
  cancelButtonProps,
  width,
  style
}) => {
  const computedStyles = getComputedStyle(document.documentElement);
  const secondaryColorRed = computedStyles.getPropertyValue('--secondary-color-red');
  const backgroundPrimary = computedStyles.getPropertyValue('--background-primary');
  const primaryColor = computedStyles.getPropertyValue('--primary-color');

  return (
    <Modal
      title={title}
      onOk={onOk}
      className={className}
      onCancel={onCancel}
      open={open}
      centered={true}
      footer={footer}
      okText={okText || 'Confirmar'}
      cancelText={cancelText || 'Cancelar'}
      okButtonProps={
        okButtonProps || { style: { backgroundColor: primaryColor, color: backgroundPrimary } }
      }
      cancelButtonProps={
        cancelButtonProps || {
          style: { backgroundColor: secondaryColorRed, color: backgroundPrimary }
        }
      }
      width={width}
      style={style}>
      {children}
    </Modal>
  );
};

ReusableModal.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  onOk: PropTypes.func,
  open: PropTypes.bool,
  okText: PropTypes.string,
  onCancel: PropTypes.func,
  cancelText: PropTypes.string,
  okButtonProps: PropTypes.object,
  cancelButtonProps: PropTypes.object,
  width: PropTypes.number,
  style: PropTypes.object,
  children: PropTypes.any,
  footer: PropTypes.any
};

export default ReusableModal;
