import { Input, Space, Table, Tag } from 'antd';
import React, { useState, useRef } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import localeObjectTable from '../../Utils/localeObjectTable';
import PropTypes from 'prop-types';
import currencyFormatter from '../../Utils/currencyFormatter';
import { DatePicker } from 'antd';
import moment from 'moment';
import es from 'antd/lib/date-picker/locale/es_ES';
import decodeToken from '../../Utils/decodeToken';
import Boton from '../Atoms/Boton';

const TablaReporteGeneral = ({ data, reloadTable }) => {
  const { RangePicker } = DatePicker;
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  let decodedToken = decodeToken(localStorage.getItem('token'));
  const isAdmin = decodedToken?.role?.name === 'administrator';
  const isSuperAdmin = decodedToken?.role?.name === 'Super_Admin';
  const [pageSize, setPageSize] = useState(15);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: '0 8px 8px 8px'
        }}
        onKeyDown={(e) => e.stopPropagation()}>
        <Boton
          type="link"
          size="small"
          onClick={() => {
            close();
          }}
          className="button-close-search-filter-table">
          X
        </Boton>
        <Input
          ref={searchInput}
          placeholder={`Buscar`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block'
          }}
        />
        <Space>
          <Boton
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small">
            Buscar
          </Boton>
          <Boton onClick={() => clearFilters && handleReset(clearFilters)} size="small" danger>
            Limpiar
          </Boton>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      )
  });

  const tablaReporte = [
    {
      title: 'ID SOLICITUD',
      dataIndex: 'id',
      key: 'id',
      fixed: 'left',
      ...getColumnSearchProps('id'),
      sorter: (a, b) => a.id - b.id,
      sortDirections: ['ascend', 'descend'],
      render: (text) => `GTO-${text}`
    },
    {
      title: 'NOMBRE SOLICITANTE',
      dataIndex: 'user_name',
      key: 'user_name',
      ...getColumnSearchProps('user_name'),
      sorter: (a, b) => a.user_name.localeCompare(b.user_name),
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'FECHA DE CREACIÓN',
      dataIndex: 'created_at',
      key: 'created_at',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
        <div
          style={{
            padding: '0 8px 8px 8px'
          }}
          onKeyDown={(e) => e.stopPropagation()}>
          <Boton
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
            className="button-close-search-filter-table">
            X
          </Boton>
          <RangePicker
            locale={es}
            placeholder={['Fecha inicial', 'Fecha final']}
            value={selectedKeys[0]}
            onChange={(dates) => {
              setSelectedKeys([dates]);
            }}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Boton
              type="primary"
              size="small"
              onClick={() => {
                confirm();
              }}
              icon={<SearchOutlined />}>
              Buscar
            </Boton>
            <Boton onClick={clearFilters} size="small" danger>
              Limpiar
            </Boton>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{
            color: filtered ? '#1890ff' : undefined
          }}
        />
      ),
      onFilter: (value, record) => {
        if (!value) return true;
        const start = value[0].startOf('day').toDate(); // Convertir a objeto Date
        const end = value[1].endOf('day').toDate(); // Convertir a objeto Date
        const created = moment(record.created_at, 'DD-MM-YYYY').toDate(); // Convertir a objeto Date
        return created >= start && created <= end;
      },
      sorter: (a, b) => moment(a.created_at, 'DD-MM-YYYY') - moment(b.created_at, 'DD-MM-YYYY'),
      sortDirections: ['ascend', 'descend']
    },
    isAdmin || isSuperAdmin
      ? {
          title: 'FECHA DE COMPRA',
          dataIndex: 'creation_date',
          key: 'creation_date',
          filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
              style={{
                padding: '0 8px 8px 8px'
              }}
              onKeyDown={(e) => e.stopPropagation()}>
              <Boton
                type="link"
                size="small"
                onClick={() => {
                  close();
                }}
                className="button-close-search-filter-table">
                X
              </Boton>
              <RangePicker
                locale={es}
                placeholder={['Fecha inicial', 'Fecha final']}
                value={selectedKeys[0]}
                onChange={(dates) => {
                  setSelectedKeys([dates]);
                }}
                style={{ marginBottom: 8, display: 'block' }}
              />
              <Space>
                <Boton
                  type="primary"
                  onClick={() => {
                    confirm();
                  }}
                  icon={<SearchOutlined />}
                  size="small">
                  Buscar
                </Boton>
                <Boton onClick={clearFilters} size="small" danger>
                  Limpiar
                </Boton>
              </Space>
            </div>
          ),
          filterIcon: (filtered) => (
            <SearchOutlined
              style={{
                color: filtered ? '#1890ff' : undefined
              }}
            />
          ),
          onFilter: (value, record) => {
            if (!value) return true;
            const start = value[0].startOf('day').toDate(); // Convertir a objeto Date
            const end = value[1].endOf('day').toDate(); // Convertir a objeto Date
            const created = moment(record.creation_date, 'DD-MM-YYYY').toDate(); // Convertir a objeto Date
            return created >= start && created <= end;
          },
          sorter: (a, b) =>
            moment(a.creation_date, 'DD-MM-YYYY') - moment(b.creation_date, 'DD-MM-YYYY'),
          sortDirections: ['ascend', 'descend']
        }
      : null,
    {
      title: 'EMPRESA',
      dataIndex: 'company_name',
      key: 'company_name',
      filters: [
        {
          text: 'PROMO LIFE',
          value: 'Promo life'
        },
        {
          text: 'BH TRADE MARKET',
          value: 'BH Trademarket'
        }
      ],
      onFilter: (value, record) => record.company_name.indexOf(value) === 0,
      sorter: (a, b) => a.company_name.length - b.company_name.length,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'DEPARTAMENTO',
      dataIndex: 'department_name',
      key: 'department_name',
      ...getColumnSearchProps('department_name'),
      sorter: (a, b) => a.department_name.length - b.department_name.length,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'CENTRO DE COSTOS',
      dataIndex: 'center_name',
      key: 'center_name',
      ...getColumnSearchProps('center_name'),
      sorter: (a, b) => a.center_name.localeCompare(b.center_name),
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'CATEGORÍA',
      dataIndex: 'spent_outgo_type',
      key: 'spent_outgo_type',
      filters: [
        {
          text: 'FIJO',
          value: 'FIJO'
        },
        {
          text: 'VARIABLE',
          value: 'VARIABLE'
        }
      ],
      onFilter: (value, record) => record.spent_outgo_type.indexOf(value) === 0,
      sorter: (a, b) => a.spent_outgo_type.localeCompare(b.spent_outgo_type),
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'CONCEPTO',
      dataIndex: 'spent_name',
      key: 'spent_name',
      ...getColumnSearchProps('spent_name'),
      sorter: (a, b) => a.spent_name.localeCompare(b.spent_name),
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'AUTORIZACIÓN JEFE DIRECTO',
      dataIndex: 'approved_by',
      key: 'approved_by',
      ...getColumnSearchProps('approved_by'),
      sorter: (a, b) => a.approved_by.localeCompare(b.approved_by),
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'AUTORIZACIÓN ADMINISTRADOR',
      dataIndex: 'admin_approved',
      key: 'admin_approved',
      ...getColumnSearchProps('admin_approved'),
      sorter: (a, b) => a.admin_approved.localeCompare(b.admin_approved),
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'TOTAL',
      dataIndex: 'total',
      key: 'total',
      ...getColumnSearchProps('total'),
      sorter: (a, b) => a.total - b.total,
      sortDirections: ['ascend', 'descend'],
      render: (text) => currencyFormatter(text)
    },
    {
      title: 'MÉTODO DE PAGO',
      dataIndex: 'payment_method',
      key: 'payment_method',
      filters: [
        {
          text: 'SIN MÉTODO DE PAGO',
          value: 'SIN MÉTODO DE PAGO'
        },
        {
          text: 'AME EXPRESS',
          value: 'AME EXPRESS'
        },
        {
          text: 'EFECTIVO',
          value: 'EFECTIVO'
        },
        {
          text: 'TRANSFERENCIA',
          value: 'TRANSFERENCIA'
        }
      ],
      onFilter: (value, record) => record.payment_method == value,
      sorter: (a, b) => a.payment_method.localeCompare(b.payment_method),
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'ESTADO',
      dataIndex: 'purchase_status',
      key: 'purchase_status',
      filters: [
        {
          text: 'EN PROCESO',
          value: 'En proceso'
        },
        {
          text: 'ADQUISICIÓN',
          value: 'Compra'
        },
        {
          text: 'ENTREGADO',
          value: 'Entregado'
        },
        {
          text: 'RECIBIDO',
          value: 'Recibido'
        },
        {
          text: 'DEVOLUCIÓN',
          value: 'Devolución'
        }
      ],

      onFilter: (value, record) => record.purchase_status === value,
      sorter: (a, b) => a.purchase_status.localeCompare(b.purchase_status),
      sortDirections: ['ascend', 'descend'],
      render: (text) => {
        switch (text) {
          case 'En proceso':
            return <Tag color="geekblue">En Proceso</Tag>;
          case 'Compra':
            return <Tag color="purple">Adquisición</Tag>;
          case 'Entregado':
            return <Tag color="gold">Entregado</Tag>;
          case 'Recibido':
            return <Tag color="success">Recibido</Tag>;
          case 'Devolución':
            return <Tag color="error">Devolución</Tag>;
          default:
            return <Tag color="default">ERROR</Tag>;
        }
      }
    },
    {
      title: 'TIPO DE ESTADO',
      dataIndex: 'type_status',
      key: 'type_status',
      filters: [
        {
          text: 'NORMAL',
          value: 'normal'
        },
        {
          text: 'CANCELADO',
          value: 'cancelado'
        },
        {
          text: 'EN PROCESO',
          value: 'en proceso'
        },
        {
          text: 'RECHAZADA',
          value: 'rechazada'
        }
      ],
      onFilter: (value, record) => record.type_status === value,
      sorter: (a, b) => a.type_status.localeCompare(b.type_status),
      sortDirections: ['ascend', 'descend'],
      render: (text) => {
        switch (text) {
          case 'normal':
            return <Tag color="green-inverse">Normal</Tag>;
          case 'cancelado':
            return <Tag color="red-inverse">Cancelado</Tag>;
          case 'rechazada':
            return <Tag color="magenta-inverse">Rechazada</Tag>;
          case 'en proceso':
            return <Tag color="blue-inverse">En Proceso</Tag>;
          default:
            return <Tag color="default">ERROR</Tag>;
        }
      }
    }
  ].filter(Boolean);

  const newDataSOL = data?.map((item) => {
    return {
      key: item?.id,
      id: item?.id,
      company_name: item?.company[0].company_name,
      created_at: item?.created_at,
      creation_date: item?.creation_date,
      center_name: item?.center[0].center_name,
      spent_outgo_type: item?.spent[0].spent_outgo_type,
      spent_name: item?.spent[0].spent_name,
      approved_by: item?.approved_by ? item?.approved_by : 'PENDIENTE',
      admin_approved: item?.admin_approved ? item?.admin_approved : 'PENDIENTE',
      total: item.total,
      payment_method: item?.payment_method,
      purchase_status: item?.purchase_status,
      type_status: item?.type_status,
      department_name: item?.department_name,
      user_name: item?.user_name
    };
  });
  return (
    <>
      <Table
        loading={reloadTable}
        columns={tablaReporte}
        dataSource={newDataSOL}
        pagination={{
          position: ['bottomCenter'],
          pageSize: pageSize,
          showSizeChanger: false,
          total: data.total,
          showTotal: (total, range) =>
            `Mostrando solicitudes del ${range[0]} al ${range[1]} de un total de ${total}`
        }}
        className="mt-4 mb-4"
        locale={localeObjectTable}
        scroll={{
          x: true
        }}
      />
      <div className="flex flex-col">
        <span>SG por página:</span>
        <Input
          className="ml-2"
          size="small"
          placeholder="Registros por página"
          type="number"
          defaultValue={pageSize}
          onChange={(e) => setPageSize(Number(e.target.value))}
        />
        <Boton
          type="link"
          onClick={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}>
          Ir al inicio
        </Boton>
      </div>
    </>
  );
};

TablaReporteGeneral.propTypes = {
  data: PropTypes.array,
  reloadTable: PropTypes.bool
};

export default TablaReporteGeneral;
