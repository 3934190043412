import PropTypes from 'prop-types';
import ReusableModal from '../Atoms/Modal';
import { Form, Input, Radio } from 'antd';
import { useEffect, useState } from 'react';
import Boton from '../Atoms/Boton';
import { FormOutlined } from '@ant-design/icons';
import clienteAxios from '../../Utils/clienteAxios';
import Swal from 'sweetalert2';

const ModalCheckList = ({ data, setLoading, loading }) => {
  const [isModalOpenCL, setIsModalOpenCL] = useState(false);
  const [form] = Form.useForm();
  const [inputSize, setInputSize] = useState('middle');

  const updateInputSize = () => {
    if (window.innerWidth <= 422) {
      setInputSize('small');
    } else {
      setInputSize('middle');
    }
  };

  useEffect(() => {
    // Agregar un event listener para detectar cambios en el tamaño de la ventana
    window.addEventListener('resize', updateInputSize);
    // Llamar a la función una vez al cargar el componente para establecer el tamaño inicial
    updateInputSize();
    // Limpiar el event listener al desmontar el componente
    return () => window.removeEventListener('resize', updateInputSize);
  }, []); // El array vacío como segundo argumento asegura que esto solo se ejecute una vez

  const showModal = () => {
    setIsModalOpenCL(true);
  };

  const handleOk = () => {
    setIsModalOpenCL(false);
  };

  const handleCancel = () => {
    setIsModalOpenCL(false);
    form.resetFields();
  };

  const onFinish = (values) => {
    const transformedData = Object.entries(values).map(([description, status_checklist]) => ({
      description,
      status_checklist
    }));

    let arraymap = [];
    filterData.map((item) => {
      arraymap.push({
        description: item?.description,
        status_checklist: item?.status_checklist
      });
    });

    const objetosConCambios = [];
    arraymap.forEach((item1) => {
      const item2 = transformedData.find((el) => el.description === item1.description);
      if (item2 && item1.status_checklist !== item2.status_checklist) {
        objetosConCambios.push(item2);
      }
    });

    postCheccList(arraymap.length > 0 ? objetosConCambios : transformedData);
  };

  const numPedido = data?.additional_information?.code_sale;

  const postCheccList = (dataPost) => {
    dataPost.length > 0
      ? Swal.fire({
          title: '¿Deseas confirmar el CheckList?',
          icon: 'question',
          confirmButtonText: `Confirmar`,
          denyButtonText: `Cancelar`
        }).then((result) => {
          if (result.isConfirmed) {
            clienteAxios
              .post(`/crear-checklist/${numPedido}`, dataPost)
              .then((response) => {
                console.log(response);
                Swal.fire({
                  title: 'Éxito!',
                  text: 'Se registró los datos correctamente.',
                  icon: 'success',
                  confirmButtonText: 'Aceptar'
                }).then(() => {
                  handleOk();
                  setLoading(!loading);
                  document.body.classList.remove('body-fixed');
                  window.scrollTo(0, scrollY);
                });
              })
              .catch(() => {
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...'
                });
              });
          }
        })
      : Swal.fire({
          icon: 'error',
          title: 'No has realizado cambios en el CheckList'
        });
  };

  let filterData = [];

  if (data?.check_list !== undefined && data?.check_list !== null) {
    const groupData = data?.check_list?.reduce((acc, item) => {
      acc[item?.description] = acc[item?.description] || [];
      acc[item?.description].push(item);
      return acc;
    }, {});

    filterData = Object.keys(groupData).map((key) => {
      const dates = groupData[key].map((item) => new Date(item?.created_at));
      const mostRecentDate = new Date(Math.max.apply(null, dates));
      return groupData[key].find(
        (item) => new Date(item?.created_at).getTime() === mostRecentDate.getTime()
      );
    });
  }
  return (
    <>
      <div className="mb-3 flex justify-content-end">
        <Boton type="primary" icon={<FormOutlined />} onClick={showModal}>
          Confirmar CheckList
        </Boton>
      </div>

      <ReusableModal
        title="CHECK LIST"
        open={isModalOpenCL}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        width={550}
        style={{ maxHeight: '95%', overflowY: 'none' }}>
        <Form
          form={form}
          onFinish={onFinish}
          labelCol={{ span: 10 }}
          labelAlign="left"
          encType="multipart/form-data"
          layout="horizontal">
          <Form.Item label="Pedido" className="mb-3" style={{ flex: '0px 0px 0px 0px' }}>
            <Input disabled value={numPedido} size={inputSize} className="text-align-center" />
          </Form.Item>
          <Form.Item
            name="OC"
            label="OC"
            className="mb-3"
            initialValue={filterData?.find((item) => item.description === 'OC')?.status_checklist}>
            <Radio.Group buttonStyle="solid" size={inputSize} style={{ width: '125%' }}>
              <Radio.Button value="Listo"> Listo </Radio.Button>
              <Radio.Button value="Pendiente">Pendiente</Radio.Button>
              <Radio.Button value="No aplica">No aplica</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="Virtual"
            label="Virtual"
            className="mb-3"
            initialValue={
              filterData?.find((item) => item.description === 'Virtual')?.status_checklist
            }>
            <Radio.Group buttonStyle="solid" size={inputSize}>
              <Radio.Button value="Listo">Listo</Radio.Button>
              <Radio.Button value="Pendiente">Pendiente</Radio.Button>
              <Radio.Button value="No aplica">No aplica</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="Logo"
            label="Logo"
            className="mb-3"
            initialValue={
              filterData?.find((item) => item.description === 'Logo')?.status_checklist
            }>
            <Radio.Group buttonStyle="solid" size={inputSize}>
              <Radio.Button value="Listo">Listo</Radio.Button>
              <Radio.Button value="Pendiente">Pendiente</Radio.Button>
              <Radio.Button value="No aplica">No aplica</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="AI"
            label="AI"
            className="mb-3"
            initialValue={filterData?.find((item) => item.description === 'AI')?.status_checklist}>
            <Radio.Group buttonStyle="solid" size={inputSize}>
              <Radio.Button value="Listo">Listo</Radio.Button>
              <Radio.Button value="Pendiente">Pendiente</Radio.Button>
              <Radio.Button value="No aplica">No aplica</Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name="Cotización proveedor"
            label="Cotización proveedor"
            className="mb-3"
            initialValue={
              filterData?.find((item) => item.description === 'Cotización proveedor')
                ?.status_checklist
            }>
            <Radio.Group buttonStyle="solid" size={inputSize}>
              <Radio.Button value="Listo">Listo</Radio.Button>
              <Radio.Button value="Pendiente">Pendiente</Radio.Button>
              <Radio.Button value="No aplica">No aplica</Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label="Distribución"
            name="Distribución"
            className="mb-3"
            initialValue={
              filterData?.find((item) => item.description === 'Distribución')?.status_checklist
            }>
            <Radio.Group buttonStyle="solid" size={inputSize}>
              <Radio.Button value="Listo">Listo</Radio.Button>
              <Radio.Button value="Pendiente">Pendiente</Radio.Button>
              <Radio.Button value="No aplica">No aplica</Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name="Dirección de entrega"
            label="Dirección de entrega"
            className="mb-3"
            initialValue={
              filterData?.find((item) => item.description === 'Dirección de entrega')
                ?.status_checklist
            }>
            <Radio.Group buttonStyle="solid" size={inputSize}>
              <Radio.Button value="Listo">Listo</Radio.Button>
              <Radio.Button value="Pendiente">Pendiente</Radio.Button>
              <Radio.Button value="No aplica">No aplica</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="Contacto"
            label="Contacto"
            className="mb-3"
            initialValue={
              filterData?.find((item) => item.description === 'Contacto')?.status_checklist
            }>
            <Radio.Group buttonStyle="solid" size={inputSize}>
              <Radio.Button value="Listo">Listo</Radio.Button>
              <Radio.Button value="Pendiente">Pendiente</Radio.Button>
              <Radio.Button value="No aplica">No aplica</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="Datos de facturación"
            label="Datos de facturación"
            className="mb-3"
            initialValue={
              filterData?.find((item) => item.description === 'Datos de facturación')
                ?.status_checklist
            }>
            <Radio.Group buttonStyle="solid" size={inputSize}>
              <Radio.Button value="Listo">Listo</Radio.Button>
              <Radio.Button value="Pendiente">Pendiente</Radio.Button>
              <Radio.Button value="No aplica">No aplica</Radio.Button>
            </Radio.Group>
          </Form.Item>

          <div className="justify-content-end">
            <Boton danger onClick={handleCancel} className="mr-2">
              Cancelar
            </Boton>

            <Boton type="primary" htmlType="submit">
              Confirmar
            </Boton>
          </div>
        </Form>
      </ReusableModal>
    </>
  );
};

ModalCheckList.propTypes = {
  data: PropTypes.object,
  isModalOpenCL: PropTypes.bool,
  setIsModalOpenCL: PropTypes.func,
  setLoading: PropTypes.func,
  loading: PropTypes.bool
};

export default ModalCheckList;
