import React, { useState, useEffect } from 'react';
import { Input, Radio, Alert, Space, Form, Spin, message } from 'antd';
import { useParams } from 'react-router-dom';
import TablaCaracteristicasInspeccionCalidad from '../Components/Organisms/TablaCaracteristicasInspeccionCalidad';
import Boton from '../Components/Atoms/Boton';
import CrearFirmas from '../Components/Organisms/CrearFirmas';
import { useNavigate } from 'react-router';
import { ArrowLeftOutlined } from '@ant-design/icons';
import decodeToken from '../Utils/decodeToken';
import clienteAxios from '../Utils/clienteAxios';
import moment from 'moment';
import Swal from 'sweetalert2';
import TablaProductsIncidence from '../Components/Organisms/TablaProductsIncidence';
import UploadPhoto from '../Components/Molecules/UploadPhoto';
import Card from '../Components/Organisms/Card';
import getSampleQuantity from '../Utils/getSampleQuantity';
import getMaximumOfPiecesToReject from '../Utils/getMaximumPiecesToReject';

const { TextArea } = Input;

const CrearInspeccionCalidad = () => {
  const [nombreReviso, setNombreReviso] = useState('');
  const [firmaReviso, setFirmaReviso] = useState();
  const [firmaElaboro, setFirmaElaboro] = useState();
  const [loading, setLoading] = useState(true);
  const [pedidoData, setPedidoData] = useState();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [totalRejectedProducts, setTotalRejectedProducts] = useState(0);
  const [quantities, setQuantities] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [productQuantity, setProductQuantity] = useState([{ quantity_selected: 0 }]);
  const [fileList, setFileList] = useState([]);
  const [stateOfQualityData, setStateOfQualityData] = useState({
    total: 0,
    muestra: 0,
    maximoPiezasRechazar: 0
  });
  const [inputSize, setInputSize] = useState('middle');
  const [form] = Form.useForm();

  const updateInputSize = () => {
    if (window.innerWidth <= 422) {
      setInputSize('small');
    } else {
      setInputSize('middle');
    }
  };

  useEffect(() => {
    // Agregar un event listener para detectar cambios en el tamaño de la ventana
    window.addEventListener('resize', updateInputSize);
    // Llamar a la función una vez al cargar el componente para establecer el tamaño inicial
    updateInputSize();
    // Limpiar el event listener al desmontar el componente
    return () => window.removeEventListener('resize', updateInputSize);
  }, []); // El array vacío como segundo argumento asegura que esto solo se ejecute una vez

  const navigate = useNavigate();
  let { idPedido } = useParams();
  let { idProducto } = useParams();
  let decodedToken = decodeToken(localStorage.getItem('token'));

  let arrayFiltrado = productQuantity.filter(
    (elemento) => elemento !== undefined && elemento?.quantity_selected !== 0
  );

  useEffect(() => {
    clienteAxios
      .get(`/pedido/${idPedido}`)
      .then((response) => {
        let data = response.data;
        setPedidoData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [idPedido]);
  useEffect(() => {
    let objTemp = {};
    objTemp.total = productQuantity[0]?.quantity_selected;
    objTemp.muestra = getSampleQuantity(productQuantity[0]?.quantity_selected);
    objTemp.maximoPiezasRechazar = getMaximumOfPiecesToReject(
      productQuantity[0]?.quantity_selected
    );
    setStateOfQualityData(objTemp);
  }, [productQuantity]);

  let productOrder = pedidoData?.products_orders?.filter(
    (order) => order.id === parseInt(idProducto)
  );

  useEffect(() => {
    form.setFieldsValue({
      ['evidenciaDeProductosAfectados']: fileList
    });
  }, [fileList]);

  const handleClickCreateIncidence = (values) => {
    setFormSubmitted(true);

    values.sale_id = pedidoData?.additional_information?.id;
    values.date_inspeccion = moment().format('YYYY-MM-DD');
    values.user_signature_created = firmaElaboro;
    values.user_reviewed = nombreReviso;
    values.user_signature_reviewed = firmaReviso;

    //Create the required array of objects for the post request
    let features_quantity = {
      wrong_pantone_color: quantities[0],
      damage_logo: quantities[1],
      incorrect_logo: quantities[2],
      incomplete_pieces: quantities[3],
      merchandise_not_cut: quantities[4],
      different_dimensions: quantities[5],
      damaged_products: quantities[6],
      product_does_not_perform_its_function: quantities[7],
      wrong_product_code: quantities[8],
      total: totalRejectedProducts
    };
    values.quantity_revised = stateOfQualityData?.total;
    values.quantity_denied = stateOfQualityData?.maximoPiezasRechazar;

    values.features_quantity = features_quantity;

    // values.products_selected = arrayFiltrado;
    values.sample = stateOfQualityData?.muestra;
    values.odoo_product_id = arrayFiltrado[0]?.odoo_product_id;
    values.code_order = arrayFiltrado[0]?.code_order;
    values.quantity_selected = arrayFiltrado[0]?.quantity_selected;

    delete values.firmaElaboro;
    delete values.firmaReviso;
    delete values.inputQuantity_0;
    delete values.inputQuantity_1;
    delete values.inputQuantity_2;
    delete values.inputQuantity_3;
    delete values.inputQuantity_4;
    delete values.inputQuantity_5;
    delete values.inputQuantity_6;
    delete values.inputQuantity_7;
    delete values.inputQuantity_8;
    delete values.observaciones;
    delete values.tipo_de_producto;
    delete values.nombreReviso;

    handleUpload(fileList, values);
  };

  const handleUpload = (fileList, valuesOfForm) => {
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append('files[]', file);
    });
    Swal.fire({
      title: '¿Estás seguro de crear la inspección?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed && fileList?.length > 0) {
        clienteAxios
          .post('pedido/files/inspections', formData)
          .then((response) => {
            let imageUrlArr = response.data.images;
            message.success('La imagen se subió correctamente.');
            valuesOfForm.files = JSON.stringify(imageUrlArr);
            postQualityInspection(valuesOfForm);
            setLoading(true);
          })
          .catch((error) => {
            console.error(error);
            message.error('Hubo un error al subir la imagen.');
          });
      } else {
        message.error('Debes subir una imagen.');
      }
    });
  };

  const postQualityInspection = (values) => {
    clienteAxios
      .post(`/pedido/${idPedido}/inspections/${idProducto}`, values)
      .then((response) => {
        console.log(response);
        setLoading(false);
        Swal.fire({
          title: 'Inspección de calidad creada',
          text: 'La inspección de calidad se ha creado correctamente',
          icon: 'success',
          confirmButtonText: 'Aceptar'
        }).then(() => {
          navigate(`/pedidos/${idPedido}`);
        });
      })
      .catch((error) => {
        console.error(error);
        Swal.fire({
          title: 'Error',
          text: 'Ha ocurrido un error al crear la inspección de calidad',
          icon: 'error',
          confirmButtonText: 'Aceptar'
        });
      });
  };

  return (
    <>
      <Spin tip="Cargando..." spinning={loading}>
        <h1>
          <ArrowLeftOutlined style={{ fontSize: '2rem' }} onClick={() => navigate(-1)} /> crear
          inspección de calidad
        </h1>

        <Form
          layout="vertical"
          autoComplete="off"
          onFinish={handleClickCreateIncidence}
          scrollToFirstError={{
            behavior: (actions) => {
              return actions.forEach(({ top }) => {
                window.scrollTo({
                  top: top - 150,
                  left: 0,
                  behavior: 'smooth'
                });
              });
            }
          }}>
          <Card>
            <div className="ml-4 mr-4 mb-2">
              <strong>Empresa: </strong>
              <span>{pedidoData?.additional_information?.Company}</span>
            </div>

            <div className="ml-4 mr-4 mb-2">
              <strong>Proveedor: </strong>
              <span>{productOrder?.[0]?.provider_name}</span>
            </div>
          </Card>

          <div className="mt-3 flex flex-wrap justify-content-around">
            <Form.Item
              label={<span className="font-weight-500">Tipo de producto</span>}
              name="type_product"
              rules={[
                {
                  required: true,
                  message: 'Por favor indica el tipo de producto!'
                }
              ]}>
              <Radio.Group
                size={inputSize}
                options={[
                  {
                    label: 'Limpio',
                    value: 'limpio'
                  },
                  {
                    label: 'Maquilado',
                    value: 'maquilado'
                  }
                ]}
                optionType="button"
                buttonStyle="solid"
              />
            </Form.Item>
          </div>
          <h2 className="">PRODUCTO</h2>

          <TablaProductsIncidence
            visible={false}
            data={productOrder}
            productSelec={true}
            productQuantity={productQuantity}
            setProductQuantity={setProductQuantity}></TablaProductsIncidence>

          <h2 className="mt-3">DATOS DE NIVELES DE CALIDAD</h2>
          <div className="flex flex-column align-items-center">
            <Card>
              <div className="ml-2 mr-2">
                <strong>TOTAL DE PIEZAS: </strong>
                <span>{stateOfQualityData?.total}</span>
              </div>

              <div className="ml-2 mr-2">
                <strong>MUESTRA: </strong>
                <span>{stateOfQualityData?.muestra}</span>
              </div>

              <div className="ml-2 mr-2">
                <strong>MÁXIMO DE PIEZAS A RECHAZAR: </strong>
                <span>{stateOfQualityData?.maximoPiezasRechazar}</span>
              </div>
            </Card>
            <Alert
              description="En esta sección ingresa el número de productos que tienen un defecto en una o más características."
              type="info"
              showIcon
              className="mb-3"
            />

            <TablaCaracteristicasInspeccionCalidad
              type="post"
              totalRejectedProducts={totalRejectedProducts}
              setTotalRejectedProducts={setTotalRejectedProducts}
              setQuantities={setQuantities}
              quantities={quantities}
            />
          </div>

          <h2 className="mt-4">EVIDENCIA DE PRODUCTOS AFECTADOS</h2>
          <Form.Item
            className="mt-2"
            name="evidenciaDeProductosAfectados"
            label="Fotografía de los productos afectados">
            <UploadPhoto setFileList={setFileList} fileList={fileList} />
          </Form.Item>

          <h2 className="mt-5 text-align-center" name="observations">
            OBSERVACIONES
          </h2>
          <Form.Item className="mt-3 mb-4 flex justify-content-center" name="observations">
            <TextArea
              placeholder="Escribe aquí tus observaciones..."
              rows={3}
              cols={73}
              allowClear
            />
          </Form.Item>

          <div className="mt-5">
            <CrearFirmas
              nombreElaboro={decodedToken?.user?.name}
              nombreReviso={nombreReviso}
              setNombreReviso={setNombreReviso}
              firmaReviso={firmaReviso}
              setFirmaReviso={setFirmaReviso}
              firmaElaboro={firmaElaboro}
              setFirmaElaboro={setFirmaElaboro}
              formSubmitted={formSubmitted}
            />
          </div>
          <div className="justify-content-end mt-3">
            <Space align="end" direction="horizontal">
              <Boton type="primary" htmlType="submit">
                Crear Inspección De Calidad
              </Boton>
            </Space>
          </div>
        </Form>
      </Spin>
    </>
  );
};

export default CrearInspeccionCalidad;
