import React, { useState, useEffect } from 'react';
import Card from '../Components/Organisms/Card';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Image, Spin } from 'antd';
import { useParams } from 'react-router-dom';
import clienteAxios from '../Utils/clienteAxios';
import { useNavigate } from 'react-router';
import parseDate from '../Utils/parseDate';
import ConsultarFirmas from '../Components/Organisms/ConsultaFirmas';
import TextArea from 'antd/es/input/TextArea';
import TablaCaracteristicasInspeccionCalidad from '../Components/Organisms/TablaCaracteristicasInspeccionCalidad';
import TablaProductsIncidence from '../Components/Organisms/TablaProductsIncidence';
import { photosEndpoint } from '../Constants/apiEndpoint';

const ConsultarInspeccionCalidad = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalRejectedProducts, setTotalRejectedProducts] = useState(0);
  // const [photos, setPhotos] = useState([]);
  const navigate = useNavigate();

  let { idInspeccion } = useParams();

  useEffect(() => {
    clienteAxios
      .get(`/inspections/${idInspeccion}`)
      .then((response) => {
        let data = response?.data;
        console.log('data', data);
        setLoading(false);
        data.evidence = JSON.parse(data?.inspections?.files).map((url) => photosEndpoint + url);
        setData(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  console.log('data', data);

  let nuevoObjeto;

  if (Object.values(data)?.length > 0) {
    let producsInspeccion = data?.products_inspection;
    const { description_product, ...resto } = producsInspeccion;

    nuevoObjeto = { description: description_product, ...resto };
  }

  return (
    <Spin tip="Cargando..." spinning={loading}>
      <>
        <h1>
          <ArrowLeftOutlined
            style={{ fontSize: '2rem' }}
            onClick={() => navigate(-1)}
            className="mr-2"
          />
          CONSULTA DE INSPECCIÓN DE CALIDAD
        </h1>
        <Card title="DETALLES DEL PEDIDO" className="mt-5">
          <p className="ml-4 mr-4 mb-2">
            <strong>ID Inspección: </strong>
            <span>{data?.inspections?.code_inspection}</span>
          </p>

          <p className="ml-4 mr-4 mb-2">
            <strong>Fecha: </strong>
            <span>{parseDate(data?.inspections?.date_inspection, 'short')}</span>
          </p>

          <p className="ml-4 mr-4 mb-2">
            <strong> Tipo de producto: </strong>
            <span>{data?.inspections?.type_product || 'No hay información'}</span>
          </p>
        </Card>
        <h2 className="mt-5 mb-2">PRODUCTOS INSPECCIONADOS</h2>
        <TablaProductsIncidence
          data={[nuevoObjeto]}
          productSelec={true}
          type="get"></TablaProductsIncidence>
        <div className="flex flex-column align-items-center">
          <Card title="DATOS DE NIVELES DE CALIDAD">
            <p className="ml-4 mr-4 mb-2">
              <strong>TOTAL DE PIEZAS: </strong>
              <span>{data?.inspections?.quantity_revised || 'No hay información'}</span>
            </p>

            <p className="ml-4 mr-4 mb-2">
              <strong>MUESTRA: </strong>
              <span>{data?.inspections?.sample}</span>
            </p>

            <p className="ml-4 mr-4 mb-2">
              <strong>MÁXIMO DE PIEZAS A RECHAZAR: </strong>
              <span>{data?.inspections?.quantity_denied}</span>
            </p>
            <p className="ml-4 mr-4 mb-2">
              <strong>PIEZAS RECHAZADAS: </strong>
              <span>{data?.features_quantity?.total}</span>
            </p>
          </Card>

          <TablaCaracteristicasInspeccionCalidad
            setTotalRejectedProducts={setTotalRejectedProducts}
            totalRejectedProducts={totalRejectedProducts}
            type="get"
            factures={data?.features_quantity}></TablaCaracteristicasInspeccionCalidad>
        </div>

        <h2 className="mt-4">EVIDENCIA DE PRODUCTOS AFECTADOS</h2>
        <div
          style={{ display: 'flex', alignItems: 'center', overflowY: 'auto', maxHeight: 'auto' }}>
          {data?.evidence?.map((evidenceUrl, index) => {
            const replace = evidenceUrl.replace(/ /g, '%20');
            return (
              <div key={index} className="div-margin-image-1">
                <div className="div-margin-image-2">
                  <Image height={80} src={replace} />
                </div>
              </div>
            );
          })}
        </div>
        <div className="mb-5">
          <h2 className="mt-4">OBSERVACIONES</h2>
          <TextArea rows={2} value={data?.inspections?.observations} disabled className="mt-2" />
        </div>
        <ConsultarFirmas
          nombreElaboro={data?.inspections?.user_created}
          nombreReviso={data?.inspections?.user_reviewed}
          firmaElaboro={data?.inspections?.user_signature_created}
          firmaReviso={data?.inspections?.user_signature_reviewed}
        />
      </>
    </Spin>
  );
};

export default ConsultarInspeccionCalidad;
