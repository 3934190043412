import React from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import '../../Assets/Styles/Atoms/Boton.css';

// BUTTON TYPES
// primary   -> boton con fondo azul letras blancas
// secondary  -> boton con fondo blanco letras azul
// tertiary-filled -> boton con fondo verde letras blancas
// tertiary-outlined  -> boton con borde verde letras verde
// danger -> boton con fondo rojo letras blancas

export default function Boton(props) {
  return (
    <Button
      type={
        props.type === 'secondary'
          ? 'default'
          : props.type === 'tertiary-filled'
          ? 'primary'
          : props.type === 'tertiary-outlined'
          ? 'default'
          : props.type
      }
      danger={props.danger}
      onClick={props.onClick}
      size={props.size}
      icon={props.icon}
      style={props.style}
      block={props.block}
      disabled={props.disabled}
      className={`${
        props.type === 'secondary'
          ? 'btn-secondary'
          : props.type === 'tertiary-filled'
          ? 'btn-tertiary-filled'
          : props.type === 'tertiary-outlined'
          ? 'btn-tertiary-outlined'
          : ''
      } ${props.className} button-text ${props.danger ? 'btn-danger' : ''} ${
        props.warning ? 'btn-warning' : ''
      }`}
      htmlType={props.htmlType}>
      {props.children}
    </Button>
  );
}

Boton.propTypes = {
  children: PropTypes.any,
  type: PropTypes.string,
  danger: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.string,
  icon: PropTypes.object,
  className: PropTypes.string,
  block: PropTypes.bool,
  htmlType: PropTypes.string,
  warning: PropTypes.bool,
  disabled: PropTypes.bool,
  style: PropTypes.object
};
