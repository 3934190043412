import { Col, Form, Input, InputNumber, Row, Select, Spin } from 'antd';
import Boton from '../Components/Atoms/Boton';
import { useEffect, useState } from 'react';
import clienteAxios from '../Utils/clienteAxios';
import { useParams } from 'react-router-dom';
import parseDate from '../Utils/parseDate';

const CrearIncidenciasCalidad = () => {
  const [pedidoData, setPedidoData] = useState({});
  const [loading, setLoading] = useState(true);
  const [productOrders, setProductOrders] = useState([]);
  const [productSale, setProductSale] = useState([]);
  let { idPedido } = useParams();

  useEffect(() => {
    clienteAxios
      .get(`/pedido/${idPedido}`)
      .then((response) => {
        let data = response.data;
        setPedidoData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const fechaHoy = new Date().toLocaleDateString();

  const produuctSelectOrders = [];
  Object.keys(pedidoData).length > 0
    ? pedidoData.products_orders.map((product) => {
        produuctSelectOrders.push({
          value: product.id,
          key: JSON.stringify({
            id: product.id,
            code_order: product.code_order,
            quantity: product.quantity
          }),
          label: product.description
        });
      })
    : null;

  const produuctSelectSale = [];
  Object.keys(pedidoData).length > 0
    ? pedidoData.sales_products.map((product, index) => {
        produuctSelectSale.push({
          value: index,
          key: JSON.stringify({
            id: index,
            logo: product.logo,
            quantity: product.quantity_invoiced
          }),
          label: product.description
        });
      })
    : null;

  const handleProductOrderChange = (selectedOption) => {
    setProductOrders(JSON.parse(selectedOption.key));
  };

  const handleProductSaleChange = (selectedOption) => {
    setProductSale(JSON.parse(selectedOption.key));
  };

  console.log('productSale', productSale);

  const orderProduct = pedidoData.orders?.find(
    (productOrder) => productOrder.code_order === productOrders.code_order
  );

  let OC = productOrders?.code_order?.substring(0, 2) === 'OC';

  return (
    <>
      <h1>Crear Incidencias de Calidad</h1>
      {loading ? (
        <>
          <div className="justify-content-center mt-5 text-align-center">
            <Spin tip="Cargando..." spinning={loading} />
          </div>
        </>
      ) : (
        <>
          <Form className="mt-3">
            <Row>
              <Col xs={24} lg={{ span: 6 }}>
                <Form.Item label="Folio de la incidencia">
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={24} lg={{ span: 6, offset: 1 }}>
                <Form.Item label="Dias en almacén">
                  <InputNumber />
                </Form.Item>
              </Col>

              <Col xs={24} lg={{ span: 10, offset: 1 }}>
                <Form.Item label="Fecha incidencia">
                  <Input
                    disabled
                    value={parseDate(fechaHoy, 'short')}
                    className="text-align-center"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <h3 className="mb-2">PRODUCTOS DEL PEDIDO</h3>
              </Col>
            </Row>

            <Row>
              <Col xs={24} lg={{ span: 13 }}>
                <Form.Item label="Producto">
                  <Select
                    options={produuctSelectSale}
                    onChange={handleProductSaleChange}
                    labelInValue></Select>
                </Form.Item>
              </Col>

              <Col xs={24} lg={{ span: 3, offset: 1 }}>
                <Form.Item label="Cantidad">
                  <Input disabled value={productSale?.quantity} className="text-align-center" />
                </Form.Item>
              </Col>

              <Col xs={24} lg={{ span: 6, offset: 1 }}>
                <Form.Item label="Logo">
                  <Input disabled value={productSale?.logo} className="text-align-center" />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <h3 className="mb-2"> PRODUCTOS OC / OT</h3>
              </Col>
              <Col xs={24} lg={{ span: 13 }}>
                <Form.Item label="Producto">
                  <Select
                    options={produuctSelectOrders}
                    onChange={handleProductOrderChange}
                    labelInValue></Select>
                </Form.Item>
              </Col>

              <Col xs={24} lg={{ span: 3, offset: 1 }}>
                <Form.Item label="Cantidad">
                  <Input disabled value={productOrders?.quantity} className="text-align-center" />
                </Form.Item>
              </Col>

              {OC === true ? (
                <Col xs={24} lg={{ span: 6, offset: 1 }}>
                  <Form.Item label="Distribuidor">
                    <Input
                      disabled
                      value={OC === true ? orderProduct?.provider_name : null}
                      className="text-align-center"
                    />
                  </Form.Item>
                </Col>
              ) : (
                <Col xs={24} lg={{ span: 6, offset: 1 }}>
                  <Form.Item label="Maquilador">
                    <Input disabled value={OC === false ? orderProduct?.provider_name : null} />
                  </Form.Item>
                </Col>
              )}
            </Row>

            <Row>
              <Col xs={24} lg={{ span: 6 }}>
                <Form.Item label="Piezas correctas">
                  <InputNumber />
                </Form.Item>
              </Col>

              <Col xs={24} lg={{ span: 6, offset: 1 }}>
                <Form.Item label="Piezas con defecto">
                  <InputNumber />
                </Form.Item>
              </Col>

              <Col xs={24} lg={{ span: 10, offset: 1 }}>
                <Form.Item label="% Defecto" name="porcentajeDefecto">
                  <Input disabled className="text-align-center" />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xs={24} lg={{ span: 13 }}>
                <Form.Item label="Origen del error (Responsable)">
                  <Select>
                    <Select.Option value="1">Proveedor</Select.Option>
                    <Select.Option value="2">Maquilador</Select.Option>
                    <Select.Option value="3">Almacén</Select.Option>
                    <Select.Option value="4">Transporte</Select.Option>
                    <Select.Option value="5">Cliente</Select.Option>
                    <Select.Option value="6">Administrativo</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xs={24} lg={{ span: 13 }}>
                <Form.Item label="Causa General">
                  <Select>
                    <Select.Option value="1">Materiales</Select.Option>
                    <Select.Option value="2">Proceso</Select.Option>
                    <Select.Option value="3">Producto</Select.Option>
                    <Select.Option value="4">Empaque</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xs={24} lg={{ span: 13 }}>
                <Form.Item label="Descripción De Devolución">
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <div className="justify-content-end">
              <Boton type="primary" htmlType="submit">
                Guardar
              </Boton>
            </div>
          </Form>

          <h3>Solución</h3>
          <Form className="mt-3">
            <Row>
              <Col xs={24} lg={{ span: 13 }}>
                <Form.Item label="Propuesta de solución">
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xs={24} lg={{ span: 13 }}>
                <Form.Item label="Responssable De Seguimiento">
                  <Select
                    options={produuctSelectSale}
                    onChange={handleProductSaleChange}
                    labelInValue></Select>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xs={24} lg={{ span: 13 }}>
                <Form.Item label="Reposicion fuera de tiempo">
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xs={24} lg={{ span: 6 }}>
                <Form.Item label="Fecha de entrega de la incidencia">
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} lg={{ span: 6, offset: 1 }}>
                <Form.Item label="Días de proceso de la incidencia">
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <div className="justify-content-end">
              <Boton type="primary" htmlType="submit">
                Guardar
              </Boton>
            </div>
          </Form>

          <h3>Costo $</h3>
          <Form className="mt-3">
            <Row>
              <Col xs={24} lg={{ span: 6 }}>
                <Form.Item label="ODC MAT Limpio">
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={24} lg={{ span: 6, offset: 1 }}>
                <Form.Item label="ODC MAT Limpio">
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={24} lg={{ span: 10, offset: 1 }}>
                <Form.Item label="Costo Final Material Limpio">
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xs={24} lg={{ span: 6 }}>
                <Form.Item label="ODC Impresión">
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={24} lg={{ span: 6, offset: 1 }}>
                <Form.Item label="Costo de impresion Por Pieza">
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xs={24} lg={{ span: 13 }}>
                <Form.Item label="C.U PROD (Impreso)">
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} lg={{ span: 10, offset: 1 }}>
                <Form.Item label="Costo Final">
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>

            <div className="justify-content-end">
              <Boton type="primary" htmlType="submit">
                Guardar
              </Boton>
            </div>
          </Form>

          <h3>Cierre</h3>
          <Form className="mt-3">
            <Row>
              <Col xs={24} lg={{ span: 6 }}>
                <Form.Item label="Estatus">
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={24} lg={{ span: 6, offset: 1 }}>
                <Form.Item label="Aplicación">
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xs={24} lg={{ span: 13 }}>
                <Form.Item label="Notas para aplicación">
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xs={24} lg={{ span: 6 }}>
                <Form.Item label="Responsable Seguimiento Final">
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={24} lg={{ span: 6, offset: 1 }}>
                <Form.Item label="Estaus Final">
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xs={24} lg={{ span: 6 }}>
                <Form.Item label="Fecha Cierre Total">
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={24} lg={{ span: 6, offset: 1 }}>
                <Form.Item label="Nota De Credito">
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={24} lg={{ span: 10, offset: 1 }}>
                <Form.Item label="Días De Proceso Total De Incidencia">
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>

            <div className="justify-content-end">
              <Boton type="primary" htmlType="submit">
                Guardar
              </Boton>
            </div>
          </Form>
        </>
      )}
    </>
  );
};

export default CrearIncidenciasCalidad;
