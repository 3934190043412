import { Input, Space, Table, Tag, Dropdown, Tabs } from 'antd';
import { useEffect, useState, useRef } from 'react';
import Boton from '../Components/Atoms/Boton';
import {
  SearchOutlined,
  SyncOutlined,
  UserAddOutlined,
  InteractionOutlined,
  StopOutlined,
  EditOutlined,
  DownOutlined,
  SolutionOutlined
} from '@ant-design/icons';
import clienteAxios from '../Utils/clienteAxios';
import { v4 as uuid } from 'uuid';
import Swal from 'sweetalert2';
import Highlighter from 'react-highlight-words';
import NewUser from '../Components/Organisms/NewUser';
import TablaManagers from '../Components/Organisms/TablaManagers';
import ModalManagerDepar from '../Components/Organisms/ModalManagerDepar';

const Users = () => {
  const [data, setData] = useState();
  const [modalUser, setModalUser] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userAction, setUserAction] = useState(false);
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [searchFilters, setSearchFilters] = useState([]);
  const [dataUser, setDataUser] = useState({});
  const [scrollY, setScrollY] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataManager, setDataManager] = useState();
  const [nameManager, setNameManager] = useState(null);
  const [nameDepartment, setNameDepartment] = useState(null);

  let OptionDepartment = [
    { value: 'Administración', label: 'Administración', key: 1 },
    { value: 'Almacén', label: 'Almacén', key: 2 },
    { value: 'Calidad', label: 'Calidad', key: 3 },
    { value: 'Compras', label: 'Compras', key: 4 },
    { value: 'Dirección', label: 'Dirección', key: 5 },
    { value: 'Diseño Operativo', label: 'Diseño Operativo', key: 6 },
    { value: 'Importaciones', label: 'Importaciones', key: 7 },
    { value: 'Logística', label: 'Logística', key: 8 },
    { value: 'Mesa De Control', label: 'Mesa De Control', key: 9 },
    { value: 'Producción', label: 'Producción', key: 10 },
    { value: 'Recursos Humanos', label: 'Recursos Humanos', key: 11 },
    { value: 'Tecnología E Innovación', label: 'Tecnología E Innovación', key: 12 },
    { value: 'Ventas', label: 'Ventas', key: 13 },
    { value: 'Sin Definir', label: 'Sin Definir', key: 14 },
    { value: 'Marketing Promo Life', label: 'Marketing Promo Life', key: 15 }
  ];

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const [data, dataManager] = await Promise.all([
          clienteAxios.get(`/users`),
          clienteAxios.get(`/managers/department`)
        ]);
        const reversedDataManager = dataManager.data.Managers.reverse();
        setData(data.data[0]);
        setDataManager(reversedDataManager);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [userAction]);

  const namesUsers = data?.map((item) => {
    return {
      value: item?.id,
      label: item?.name,
      key: item?.id
    };
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    const newSearchFilter = {
      name: dataIndex,
      value: selectedKeys[0]
    };
    setSearchFilters([...searchFilters, newSearchFilter]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  function deleteUser(id) {
    console.log('id', id);
    Swal.fire({
      title: '¿Deseas eliminar este usuario?',
      icon: 'warning',
      reverseButtons: true,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Aceptar',
      confirmButtonColor: '#1677ff',
      cancelButtonColor: '#ff4d4f'
    })
      .then((result) => {
        if (result.isConfirmed) {
          clienteAxios.delete(`/users/${id}`).then(() => {
            Swal.fire({
              title: 'Usuario eliminado correctamente',
              text: 'El usuario se ha eliminado correctamente',
              icon: 'success',
              confirmButtonText: 'Aceptar'
            }).then(() => {
              setUserAction(!userAction);
            });
          });
        }
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: 'Error',
          text: 'Ups ha ocurrido un error al eliminar el usuario',
          icon: 'error',
          confirmButtonText: 'Aceptar'
        });
      });
  }

  function renviarPassword(id) {
    Swal.fire({
      title: '¿Deseas enviar su acceso a este usuario?',
      icon: 'warning',
      reverseButtons: true,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Aceptar',
      confirmButtonColor: '#1677ff',
      cancelButtonColor: '#ff4d4f'
    }).then((result) => {
      if (result.isConfirmed) {
        clienteAxios.get(`/users/sendNewAccess/${id}`).then(() => {
          Swal.fire({
            title: 'Se ha enviado el acceso al correo',
            icon: 'success',
            confirmButtonText: 'Aceptar'
          }).then(() => {
            setUserAction(!userAction);
          });
        });
      }
    });
  }

  function sincronizarUser() {
    Swal.fire({
      title: '¿Deseas sincronizar la lista de usuarios?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Aceptar'
    }).then((result) => {
      if (result.isConfirmed) {
        clienteAxios.get(`/syncUsers`).then((respuesta) => {
          Swal.fire({
            title: respuesta?.data?.msg,
            text: 'Se han sincronizado los usuarios exitosamente',
            icon: 'success',
            confirmButtonText: 'Aceptar'
          }).then(() => {
            setUserAction(!userAction);
          });
        });
      }
    });
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: '0 8px 8px 8px'
        }}
        onKeyDown={(e) => e.stopPropagation()}>
        <Boton
          type="link"
          size="small"
          onClick={() => {
            close();
          }}
          className="button-close-search-filter-table">
          X
        </Boton>
        <>
          <Input
            ref={searchInput}
            placeholder={`Buscar ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{
              marginBottom: 8,
              display: 'block'
            }}
          />
        </>

        <Space>
          <Boton
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 100,
              height: 30
            }}>
            Buscar
          </Boton>
          <Boton
            onClick={() => {
              clearFilters && handleReset(clearFilters);
            }}
            danger
            size="small"
            style={{
              width: 100,
              height: 30
            }}>
            Limpiar
          </Boton>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString()?.toLowerCase()?.includes(value?.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput?.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <>
          <Highlighter
            highlightStyle={{
              backgroundColor: '#ffc069',
              padding: 0
            }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        </>
      ) : (
        text
      )
  });

  const dataUsers = data?.map((item) => {
    return {
      key: uuid(),
      name: item?.name,
      email: item?.email,
      role: item?.what_roles[0]?.display_name,
      department: item?.department,
      company: item?.company,
      area: item?.area,
      acciones: (
        <>
          <div className="flex">
            <Boton
              className="btn-tertiary-outlined ml-1 mr-1 mt-1 mb-1"
              icon={<EditOutlined />}
              onClick={() => {
                setDataUser({
                  id: item?.id,
                  name: item?.name,
                  email: item?.email,
                  role_id: item?.what_roles[0]?.id,
                  department_id: item?.department_id,
                  company_id: item?.company_id,
                  area_id: item?.area_id
                });
                setModalUser(true);
                setScrollY(window.scrollY);
                document.body.classList.add('body-fixed');
              }}
            />
            <Boton
              className="btn-secondary ml-1 mr-1 mt-1 mb-1"
              icon={<InteractionOutlined />}
              onClick={() => renviarPassword(item.id)}
            />
            <Boton
              className="btn-danger ml-1 mr-1 mt-1 mb-1"
              danger
              icon={<StopOutlined />}
              onClick={() => deleteUser(item.id)}
            />
          </div>
        </>
      )
    };
  });

  const UserColums = [
    {
      title: 'NOMBRE COMPLETO',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name'),
      sorter: (a, b) => a?.name?.localeCompare(b?.name),
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'CORREO ELECTRÓNICO',
      dataIndex: 'email',
      key: 'email',
      ...getColumnSearchProps('email'),
      sorter: (a, b) => a?.email?.localeCompare(b?.email),
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'EMPRESA',
      dataIndex: 'company',
      key: 'company',
      ...getColumnSearchProps('company'),
      sorter: (a, b) => a?.company?.localeCompare(b?.company),
      sortDirections: ['ascend', 'descend'],
      render(department) {
        if (department === 'SIN DEFINIR' || department === null || department === undefined) {
          return <Tag color="red">Sin definir</Tag>;
        } else {
          return <Tag color="geekblue">{department}</Tag>;
        }
      }
    },
    {
      title: 'DEPARTAMENTO',
      dataIndex: 'department',
      key: 'department',
      ...getColumnSearchProps('department'),
      sorter: (a, b) => a?.department?.localeCompare(b?.department),
      sortDirections: ['ascend', 'descend'],
      render(department) {
        if (department === 'SIN DEFINIR' || department === null || department === undefined) {
          return <Tag color="red">Sin definir</Tag>;
        } else {
          return <Tag color="geekblue">{department}</Tag>;
        }
      }
    },
    {
      title: 'ÁREA',
      dataIndex: 'area',
      key: 'area',
      ...getColumnSearchProps('area'),
      sorter: (a, b) => a?.area?.localeCompare(b?.area),
      sortDirections: ['ascend', 'descend'],
      render(area) {
        if (area === 'SIN DEFINIR' || area === null || area === undefined) {
          return <Tag color="red">Sin definir</Tag>;
        } else {
          return <Tag color="geekblue">{area}</Tag>;
        }
      }
    },
    {
      title: 'ROL',
      dataIndex: 'role',
      key: 'role',
      ...getColumnSearchProps('role'),
      sorter: (a, b) => a?.role?.localeCompare(b?.role),
      sortDirections: ['ascend', 'descend'],
      render(role) {
        if (role === undefined) {
          return <Tag color="red">Sin definir</Tag>;
        } else {
          return <Tag color="geekblue">{role}</Tag>;
        }
      }
    },
    {
      title: 'ACCIONES',
      dataIndex: 'acciones',
      key: 'acciones'
    }
  ];

  const handleAdd = async () => {
    setLoading(true);
    try {
      await clienteAxios.post(`/create/manager/department`, {
        id_user: nameManager,
        id_department: nameDepartment
      });
      handleCancel();
      Swal.fire({
        icon: 'success',
        title: 'Concepto agregado correctamente!',
        showCancelButton: false,
        confirmButtonText: 'LISTO'
      });
      setUserAction(!userAction);
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: 'error',
        title: 'Error al asignar el manager al departamento!',
        showCancelButton: false,
        confirmButtonText: 'INTENTAR DE NUEVO'
      });
    } finally {
      setLoading(false); // Asegura que el estado de carga se restablezca
    }
  };

  const items = [
    {
      key: '1',
      label: (
        <Boton
          type=""
          onClick={() => sincronizarUser()}
          icon={<SyncOutlined />}
          style={{ color: 'var( --primary-color)' }}>
          Sincronizar Usuarios
        </Boton>
      )
    },
    {
      key: '2',
      label: (
        <Boton
          type=""
          onClick={() => {
            setModalUser(true);
            setScrollY(window.scrollY);
            document.body.classList.add('body-fixed');
          }}
          icon={<UserAddOutlined />}
          style={{ color: 'var( --primary-color)' }}>
          Agregar Usuario
        </Boton>
      )
    },
    {
      key: '3',
      label: (
        <Boton
          type=""
          icon={<SolutionOutlined />}
          style={{ color: 'var( --primary-color)' }}
          onClick={showModal}>
          Manager / Departamento
        </Boton>
      )
    }
  ];

  return (
    <>
      <div className="title-content-between">
        <h1 className="mb-2 mr-5">Usuarios</h1>
        <Dropdown
          menu={{
            items
          }}
          trigger={['click']}
          placement="bottomLeft">
          <Boton className="btn-secondary mb-3">
            Más opciones
            <DownOutlined />
          </Boton>
        </Dropdown>
      </div>

      <Tabs
        defaultActiveKey="1"
        items={[
          {
            key: '1',
            label: `Usuarios`,
            children: (
              <>
                <Table
                  className="mt-4 mb-4"
                  columns={UserColums}
                  loading={loading}
                  dataSource={dataUsers}
                  pagination={true}
                  scroll={{
                    x: true
                  }}></Table>
              </>
            )
          },
          {
            key: '2',
            label: `Manager de Departamento`,
            children: (
              <>
                <TablaManagers dataManager={dataManager} loading={loading} />
              </>
            )
          }
        ]}
      />

      {modalUser && (
        <NewUser
          modalUser={modalUser}
          setModalUser={setModalUser}
          userAction={userAction}
          setUserAction={setUserAction}
          dataUser={dataUser}
          setDataUser={setDataUser}
          setScrollY={setScrollY}
          scrollY={scrollY}
        />
      )}

      <ModalManagerDepar
        isModalOpen={isModalOpen}
        handleCancel={handleCancel}
        handleAdd={handleAdd}
        namesUsers={namesUsers}
        setNameManager={setNameManager}
        nameDepartment={nameDepartment}
        setNameDepartment={setNameDepartment}
        OptionDepartment={OptionDepartment}
      />
    </>
  );
};

export default Users;
