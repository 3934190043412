import React, { useEffect, useState, useLayoutEffect } from 'react';
import TablaReporteGeneral from '../Components/Organisms/TablaReporteGeneral';
import {
  ArrowLeftOutlined,
  BankOutlined,
  PaperClipOutlined,
  RiseOutlined,
  FallOutlined,
  DownOutlined,
  FileTextOutlined,
  BookOutlined,
  FileExcelOutlined
} from '@ant-design/icons';
import clienteAxios from '../Utils/clienteAxios';
import ReactHtmlTableToExcel from 'react-html-table-to-excel';
import { useNavigate } from 'react-router-dom';
import { InputNumber, Input, Upload, Dropdown, Card, Form } from 'antd';
import Boton from '../Components/Atoms/Boton';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import currencyFormatter from '../../src/Utils/currencyFormatter';
import decodeToken from '../Utils/decodeToken';
import Modal from '../Components/Atoms/Modal';
const { Meta } = Card;

const ReporteGeneral = () => {
  const [requestsPurchase, setRequestsPurchase] = useState([]);
  const [budget, setBudget] = useState([]);
  const [spent, setSpent] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpenIncome, setIsModalOpenIncome] = useState(false);
  const [isModalOpenEgress, setIsModalOpenEgress] = useState(false);
  const [budgetAmount, setBudgetAmount] = useState(0);
  const [archive, setArchive] = useState(null);
  const [isFileRemoved, setIsFileRemoved] = useState(false);
  const [nameReturn, setNameReturn] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [reloadEgress, setReloadEgress] = useState(false);
  const [reloadTable, setReloadTable] = useState(true);
  const navigate = useNavigate();
  let decodedToken = decodeToken(localStorage.getItem('token'));

  const showModalIncome = () => {
    setIsModalOpenIncome(!isModalOpenIncome);
  };

  const showModalEgress = () => {
    setIsModalOpenEgress(!isModalOpenEgress);
  };

  useLayoutEffect(() => {
    document.querySelector('.ant-table-content table').id = 'table-to-xls';
  }, []);

  useEffect(() => {
    clienteAxios
      .get(`/caja-chica/administrador/solicitudes-de-compra/ver`)
      .then((response) => {
        let data = response.data.spents;
        data.reverse();
        setRequestsPurchase(data);
        setReloadTable(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [reloadTable]);

  useEffect(() => {
    clienteAxios
      .get(`/caja-chica/information/estimate`)
      .then((response) => {
        let data = response.data;
        let dato = response.data;
        setBudget(data.AvailableBudget);
        setSpent(dato.MonthlyExpenses);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [loading]);

  const handleOk = async () => {
    try {
      if (budgetAmount <= 0) {
        Swal.fire({
          icon: 'error',
          title: '¡Cuidado!',
          confirmButtonText: 'INTENTAR DE NUEVO',
          text: 'No puedes ingresar un monto de $0!'
        });
        return;
      }
      setIsSubmitting(true);
      const result = await Swal.fire({
        title: `${currencyFormatter(budgetAmount)}`,
        text: '¿Estás seguro de ingresar este monto?',
        icon: 'question',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar'
      });
      if (result.isConfirmed) {
        await clienteAxios.post(`/caja-chica/estimate`, { total: budgetAmount });
        showModalIncome();
        Swal.fire({
          icon: 'success',
          title: 'Presupuesto ingresado correctamente!',
          showCancelButton: false,
          confirmButtonText: 'LISTO'
        }).then(() => {
          setBudgetAmount(0);
          setLoading(true);
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: '¡Error!',
        text: 'No se pudo ingresar el monto'
      });
    } finally {
      setBudgetAmount(0);
      setIsSubmitting(false);
    }
  };

  const handleReturnMoney = async () => {
    try {
      const result = await Swal.fire({
        title: 'Cuidado',
        text: '¿Estás seguro de egresar este monto?',
        icon: 'warning',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar'
      });
      if (result.isConfirmed) {
        const data = new FormData();
        data.append('total_returned', budget);
        data.append('was_returned_to', nameReturn);
        if (!isFileRemoved) {
          data.append('file', archive);
        }
        await clienteAxios.post(`/caja-chica/estimate/return`, data);
        showModalEgress();
        setLoading(true);
        setArchive(null);
        setNameReturn('');
        setReloadEgress(!reloadEgress);
        Swal.fire({
          icon: 'success',
          title: 'Presupuesto egresado correctamente!',
          showCancelButton: false,
          confirmButtonText: 'LISTO'
        });
      }
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: 'error',
        text: error.response.data.message,
        confirmButtonText: 'INTENTAR DE NUEVO'
      });
    } finally {
      setReloadEgress(!reloadEgress);
      setIsSubmitting(false);
      setArchive(null);
      setNameReturn('');
    }
  };

  const items = [
    {
      key: '1',
      label: (
        <Boton
          type=""
          onClick={showModalIncome}
          icon={<RiseOutlined />}
          style={{
            color: 'var( --secondary-color-green)'
          }}>
          Ingresar Presupuesto
        </Boton>
      )
    },
    {
      key: '2',
      label: (
        <Boton
          type=""
          onClick={showModalEgress}
          icon={<FallOutlined />}
          style={{ color: 'var(--secondary-color-red)' }}>
          Egresar Presupuesto
        </Boton>
      )
    },
    {
      key: '3',
      label: (
        <Link to="/historial-egreso-efectivo">
          <Boton
            type=""
            onClick={showModalEgress}
            icon={<FileTextOutlined />}
            style={{ color: 'var( --primary-color)' }}>
            Historial Egreso
          </Boton>
        </Link>
      )
    },
    {
      key: '4',
      label: (
        <Link to="/historial-empresas">
          <Boton type="" icon={<BankOutlined />} style={{ color: 'var( --primary-color)' }}>
            Empresa Eventuales
          </Boton>
        </Link>
      )
    },
    {
      key: '5',
      label: (
        <Link to="/historial-conceptos">
          <Boton type="" icon={<BookOutlined />} style={{ color: 'var( --primary-color)' }}>
            Conceptos
          </Boton>
        </Link>
      )
    }
  ];

  return (
    <>
      <div className="title-content-between">
        <h1 className="mb-2 mr-5">
          <ArrowLeftOutlined className="arrow-size" onClick={() => navigate(-1)} />
          Reporte General
        </h1>
        {decodedToken?.role?.name === 'administrator' ||
        decodedToken?.role?.name === 'Adquisiciones' ||
        decodedToken?.role?.name === 'Super_Admin' ||
        decodedToken?.role?.name === 'caja_chica' ? (
          <div>
            <Dropdown
              menu={{
                items
              }}
              trigger={['click']}
              placement="bottomLeft">
              <Boton className="btn-secondary">
                Más opciones
                <DownOutlined />
              </Boton>
            </Dropdown>
          </div>
        ) : null}
      </div>

      <div className="flex flex-wrap mt-1 mb-3">
        <Card hoverable className="mr-3 mt-3 width-auto" loading={loading}>
          <Meta
            title={<Link to="/historial-ingresado">PRESUPUESTO TOTAL</Link>}
            description={
              <strong
                style={{
                  fontSize: '2.5rem',
                  color:
                    budget < 0 ? 'var( --secondary-color-red)' : 'var( --secondary-color-green)'
                }}>
                {currencyFormatter(budget)}
              </strong>
            }
          />
        </Card>
        <Card hoverable className="mt-3 width-auto" loading={loading}>
          <Meta
            title={<Link to="/historial-gastado">PRESUPUESTO GASTADO</Link>}
            description={
              <strong style={{ fontSize: '2.5rem', color: 'var( --secondary-color-red)' }}>
                {currencyFormatter(spent)}
              </strong>
            }
          />
        </Card>
      </div>

      <div className="flex justify-content-end">
        <ReactHtmlTableToExcel
          id="test-table-xls-button"
          className="download-table-xls-button"
          table="table-to-xls"
          filename="Reporte General"
          sheet="Reporte General"
          buttonText={
            <>
              <FileExcelOutlined className="mr-2" />
              Exportar en Excel
            </>
          }
        />
      </div>

      <div className="flex-row flex-wrap">
        <TablaReporteGeneral
          data={requestsPurchase}
          setReloadTable={setReloadTable}
          reloadTable={reloadTable}
        />
      </div>

      <Modal
        title="INGRESAR PRESUPUESTO"
        centered
        open={isModalOpenIncome}
        onCancel={showModalIncome}
        onOk={handleOk}
        width={350}
        confirmLoading={isSubmitting}>
        <Form>
          <Form.Item label="Importe">
            <InputNumber
              formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              value={budgetAmount}
              onChange={(value) => setBudgetAmount(value)}
              className="width-100"
            />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="EGRESAR PRESUPUESTO"
        centered
        open={isModalOpenEgress}
        onCancel={showModalEgress}
        onOk={handleReturnMoney}
        confirmLoading={isSubmitting}
        key={reloadEgress}
        okText="Aceptar"
        cancelText="Cancelar">
        <Form
          labelCol={{
            span: 6
          }}
          labelAlign="left"
          wrapperCol={{
            span: 19
          }}>
          <Form.Item label="Nombre">
            <Input
              onChange={(value) => setBudget(value)}
              value={currencyFormatter(budget)}
              defaultValue={budget}
              disabled
            />
          </Form.Item>
          <Form.Item label="Nombre">
            <Input onChange={(e) => setNameReturn(e.target.value)} value={nameReturn} />
          </Form.Item>
          <Form.Item label="Archivo">
            <Upload
              maxCount={1}
              listType="picture"
              accept=".pdf, .jpg, .png, .jpeg, .doc, .docx, .xls, .xlsx"
              beforeUpload={(file) => {
                setArchive(file);
                setIsFileRemoved(false);
                return false;
              }}
              onRemove={() => {
                setArchive(null);
                setIsFileRemoved(true);
              }}
              multiple={false}>
              <Boton type="secondary" icon={<PaperClipOutlined />}>
                Adjuntar archivo o imagen
              </Boton>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ReporteGeneral;
