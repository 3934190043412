import React, { useEffect, useState } from 'react';
import { Form, Input, InputNumber, Upload, Row, Col } from 'antd';
const { TextArea } = Input;
import {
  ArrowLeftOutlined,
  PaperClipOutlined,
  MinusCircleOutlined,
  PlusOutlined
} from '@ant-design/icons';
import Boton from '../Components/Atoms/Boton';
import { useNavigate } from 'react-router-dom';
import decodeToken from '../Utils/decodeToken.jsx';
import { Select } from 'antd';
import clienteAxios from '../Utils/clienteAxios';
import Swal from 'sweetalert2';

const NuevaSolicitud = () => {
  const [archive, setArchive] = useState(null);
  const [decodedToken, setDecodedToken] = useState(null);
  const [zone, setZone] = useState([]);
  const [centername, setCenterName] = useState('');
  const [ProductType, setProductType] = useState('');
  const [spent, setSpent] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isFileRemoved, setIsFileRemoved] = useState(false);
  const [isEventualesVisible, setIsEventualesVisible] = useState(false);
  const [withoutCompany, setWithoutCompany] = useState(false);
  const [total, setTotal] = useState(0);
  const [eventuales, setEventuales] = useState([]);
  const [companyEventual, setCompanyEventual] = useState([]);
  const navigate = useNavigate();

  const changeCenter = (valor) => {
    let newSpent = spent.find((item) => item.id === valor);
    setCenterName(newSpent.center);
    let newSpent2 = spent.find((item) => item.id === valor);
    setProductType(newSpent2.spent_product_type);
  };

  useEffect(() => {
    if (localStorage.getItem('token')) {
      setDecodedToken(decodeToken(localStorage.getItem('token')));
    }
  }, []);

  useEffect(() => {
    clienteAxios
      .get(`/caja-chica/datos-solicitud`)
      .then((response) => {
        const filteredSpents = response.data.spents.filter((item) => item.status === '1');
        const sortedZone = filteredSpents
          .map((item) => ({
            value: item.id,
            label: item.concept,
            key: item.id
          }))
          .sort((a, b) => a.label.localeCompare(b.label));
        setZone(sortedZone);
        setSpent(
          filteredSpents.map((item) => ({
            id: item.id,
            name: item.concept,
            center: item.center,
            spent_product_type: item.spent_product_type,
            key: item.id
          }))
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    clienteAxios
      .get(`/caja-chica/company`)
      .then((response) => {
        let data = response.data.companies;
        let filteredData = data.filter((item) => item.status === '1');
        setCompanyEventual(
          filteredData.map((item) => ({
            value: item.id,
            label: item.name
          }))
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const enviarFormulario = (values) => {
    const { company_id, spent_id, description, type, total, users } = values;
    const data = new FormData();
    const EventualsExist = isEventualesVisible && users && users.length > 0;
    if ((spent_id === 1 || spent_id === 2 || spent_id === 35 || spent_id === 36) && !archive) {
      Swal.fire({
        icon: 'warning',
        title: '¡Cuidado!',
        text: 'Debes adjuntar un archivo',
        confirmButtonText: 'Cerrar'
      });
      return;
    }
    // Si hay usuarios eventuales, validar campos
    if (EventualsExist) {
      const eventualIncomplete = users.some(
        (user) => !user.name || !user.pay || (!withoutCompany && !user.company)
      );
      if (eventualIncomplete) {
        Swal.fire({
          icon: 'error',
          title: 'Error al crear la solicitud',
          text: 'Completa todos los campos de los usuarios eventuales',
          confirmButtonText: 'Cerrar'
        });
        return;
      }
      const totalFromInput = users.reduce((acc, user) => acc + parseFloat(user.pay || 0), 0);
      data.append('total', totalFromInput);
      users.forEach((user, index) => {
        data.append(`eventuales[${index}][name]`, user.name);
        data.append(`eventuales[${index}][pay]`, user.pay);
        data.append(`eventuales[${index}][company]`, user.company);
      });
    } else {
      data.append('total', total);
    }
    if (!isFileRemoved) {
      data.append('file', archive);
    }
    data.append('company_id', company_id);
    data.append('spent_id', spent_id);
    data.append('description', description);
    data.append('type', type);
    setIsButtonDisabled(true);
    clienteAxios
      .post(`caja-chica/solicitudes-de-compra/crear`, data)
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Creación de solicitud exitosa',
          showCancelButton: false,
          confirmButtonText: 'LISTO'
        }).then(() => {
          navigate('/solicitudes-gasto');
        });
      })
      .catch((error) => {
        console.error(error);
        setIsButtonDisabled(false);
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: error.response.data.message,
          confirmButtonText: 'Cerrar'
        });
      });
  };

  return (
    <>
      <Form
        className="bold-form"
        onFinish={enviarFormulario}
        labelCol={{
          span: 8
        }}
        labelAlign="left"
        wrapperCol={{
          span: 8
        }}
        encType="multipart/form-data"
        layout="horizontal">
        <h1 className="mb-4 mr-10">
          <ArrowLeftOutlined className="arrow-size" onClick={() => navigate(-1)} />
          Nueva Solicitud
        </h1>
        <Form.Item label="Nombre Completo">
          <Input disabled value={decodedToken?.user?.name} />
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: 'Selecciona un concepto!' }]}
          name="spent_id"
          label="Importe">
          <Select
            placeholder="Selecciona el concepto"
            options={zone}
            required
            onChange={(valor) => {
              changeCenter(valor);
              setIsEventualesVisible(valor === 21 || valor === 22);
              setWithoutCompany(valor === 21);
            }}
            mode="single"
            allowClear
            showSearch
            filterOption={(inputValue, option) =>
              option.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
            }
          />
        </Form.Item>
        <Form.Item label="Centro de Costos">
          <Input disabled value={centername} />
        </Form.Item>
        <Form.Item label="Categoría">
          <Input disabled value={ProductType} />
        </Form.Item>
        {isEventualesVisible && (
          <>
            <Form.Item required label="Eventuales">
              <Form.List name="users">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Row key={key} gutter={8}>
                        <Col span={22}>
                          <Form.Item
                            {...restField}
                            name={[name, 'name']}
                            rules={[
                              {
                                required: true,
                                message: 'Escribe el nombre del eventual'
                              }
                            ]}>
                            <Input placeholder="Nombre" />
                          </Form.Item>
                        </Col>
                        <Col span={16}>
                          <Form.Item
                            {...restField}
                            name={[name, 'company']}
                            rules={[
                              {
                                required: true,
                                message: 'Selecciona una empresa!'
                              }
                            ]}>
                            <Select
                              placeholder="Empresa"
                              options={companyEventual}
                              required
                              allowClear
                            />
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            {...restField}
                            name={[name, 'pay']}
                            rules={[
                              { required: true, message: 'Escribe un monto!' },
                              { type: 'number', min: 1, message: 'El monto debe ser mayor que 0' }
                            ]}>
                            <InputNumber
                              formatter={(value) =>
                                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                              }
                              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                              onChange={(value) => {
                                const updatedEventuales = [...eventuales];
                                updatedEventuales[name] = value;
                                setEventuales(updatedEventuales);
                                setTotal(
                                  updatedEventuales.reduce((acc, curr) => acc + (curr || 0), 0)
                                );
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <MinusCircleOutlined
                            className="mt-2"
                            onClick={() => {
                              remove(name);
                              const updatedEventuales = [...eventuales];
                              updatedEventuales.splice(name, 1);
                              setEventuales(updatedEventuales);
                              setTotal(
                                updatedEventuales.reduce((acc, curr) => acc + (curr || 0), 0)
                              );
                            }}
                          />
                        </Col>
                      </Row>
                    ))}
                    <Form.Item>
                      <Boton
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                        required>
                        Agregar Eventual
                      </Boton>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Form.Item>
          </>
        )}
        {isEventualesVisible ? (
          <Form.Item label="Importe">
            <Input disabled value={`$ ${total}`} />
          </Form.Item>
        ) : (
          <Form.Item
            rules={[
              { required: true, message: 'Escribe un monto!' },
              { type: 'number', min: 1, message: 'El monto debe ser mayor que 0' }
            ]}
            name="total"
            label="Importe">
            <InputNumber
              disabled={isEventualesVisible}
              label={1000}
              formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              value={isEventualesVisible ? total : null}
            />
          </Form.Item>
        )}
        <Form.Item
          rules={[{ required: true, message: 'Escribe una breve descripción o anexa tú link!' }]}
          name="description"
          label="Importe">
          <TextArea rows={8} showCount maxLength={500} placeholder="Escribe aquí" />
        </Form.Item>
        <Form.Item>
          <Upload
            maxCount={1}
            listType="picture"
            accept=".pdf, .jpg, .png, .jpeg, .doc, .docx, .xls, .xlsx"
            beforeUpload={(file) => {
              setArchive(file);
              setIsFileRemoved(false);
              return false;
            }}
            onRemove={() => {
              setArchive(null);
              setIsFileRemoved(true);
            }}
            multiple={false}>
            <Boton type="secondary" icon={<PaperClipOutlined />}>
              Adjuntar archivo o imagen
            </Boton>
          </Upload>
        </Form.Item>
        <div className="justify-content-end">
          <Boton type="primary" htmlType="submit" disabled={isButtonDisabled}>
            Generar Solicitud
          </Boton>
        </div>
      </Form>
    </>
  );
};
export default NuevaSolicitud;
