import React from 'react';
import { Form, Input, InputNumber, Row, Col, Select, Alert, Upload } from 'antd';
import { MinusCircleOutlined, PlusOutlined, PaperClipOutlined } from '@ant-design/icons';
import Boton from '../Atoms/Boton';
import PropTypes from 'prop-types';
import Modal from '../../Components/Atoms/Modal';

export default function ModalRegistrarEventuales({
  expenseDetail,
  modaldEventWeekend,
  handleEventualWeekend,
  finishFromEventWeekend,
  companyEventual,
  setEventualsPay,
  setTotalEventuals,
  eventualsPay,
  totalEventuals,
  setArchive,
  setIsFileRemoved,
  isButtonDisabled
}) {
  const { TextArea } = Input;

  return (
    <>
      <Modal
        title="REGISTRAR EVENTUALES"
        centered
        footer={null}
        open={modaldEventWeekend}
        onCancel={handleEventualWeekend}
        width={500}>
        <Form
          className="mt-4"
          onFinish={finishFromEventWeekend}
          labelCol={{
            span: 6
          }}
          labelAlign="left">
          {parseFloat(expenseDetail?.total) !== totalEventuals && (
            <div className="mb-4">
              <Alert
                message="El importe original ingresado en la solicitud no coincide con el importe total de los eventuales, adjunta un comproxbante para justificar el cambio."
                type="error"
                showIcon
                closable
              />
            </div>
          )}
          <Form.List name="eventuales">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Row key={key} gutter={8}>
                    <Col span={22}>
                      <Form.Item
                        {...restField}
                        name={[name, 'name']}
                        rules={[
                          {
                            required: true,
                            message: 'Escribe el nombre del eventual'
                          }
                        ]}>
                        <Input placeholder="Nombre" />
                      </Form.Item>
                    </Col>
                    <Col span={16}>
                      <Form.Item
                        {...restField}
                        name={[name, 'company']}
                        rules={[
                          {
                            required: true,
                            message: 'Selecciona una empresa!'
                          }
                        ]}>
                        <Select
                          placeholder="Empresa"
                          options={companyEventual}
                          required
                          allowClear
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        {...restField}
                        name={[name, 'pay']}
                        rules={[
                          { required: true, message: 'Escribe un monto!' },
                          { type: 'number', min: 1, message: 'El monto debe ser mayor que 0' }
                        ]}>
                        <InputNumber
                          formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                          onChange={(value) => {
                            const updatedEventuales = [...eventualsPay];
                            updatedEventuales[name] = value;
                            setEventualsPay(updatedEventuales);
                            setTotalEventuals(
                              updatedEventuales.reduce((acc, curr) => acc + (curr || 0), 0)
                            );
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={2}>
                      <MinusCircleOutlined
                        className="mt-2"
                        onClick={() => {
                          remove(name);
                          const updatedEventuales = [...eventualsPay];
                          updatedEventuales.splice(name, 1);
                          setEventualsPay(updatedEventuales);
                          setTotalEventuals(
                            updatedEventuales.reduce((acc, curr) => acc + (curr || 0), 0)
                          );
                        }}
                      />
                    </Col>
                  </Row>
                ))}
                <Form.Item>
                  <Boton type="dashed" onClick={() => add()} block icon={<PlusOutlined />} required>
                    Agregar Eventuales
                  </Boton>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Form.Item label="Importe Total">
            <Input disabled className="width-35" value={`$ ${totalEventuals}`} />
          </Form.Item>
          {parseFloat(expenseDetail?.total) !== totalEventuals && (
            <>
              <Form.Item
                label="Descripción"
                name="description"
                rules={[{ required: true, message: 'Escribe una descripción!' }]}>
                <TextArea
                  rows={4}
                  showCount
                  maxLength={150}
                  placeholder="Escribe aquí"
                  // onChange={(e) => setDescriptionSurplus(e.target.value)}
                />
              </Form.Item>
              <Form.Item label="Archivo">
                <Upload
                  maxCount={1}
                  listType="picture"
                  accept=".pdf, .jpg, .png, .jpeg, .doc, .docx, .xls, .xlsx"
                  beforeUpload={(file) => {
                    // Validar el tipo de archivo o realizar otras validaciones necesarias
                    // y luego establecer el archivo seleccionado en el estado
                    setArchive(file);
                    setIsFileRemoved(false);
                    return false; // Evita que el archivo se cargue automáticamente
                  }}
                  onRemove={() => {
                    setArchive(null);
                    setIsFileRemoved(true);
                  }}
                  multiple={false}>
                  <Boton type="secondary" icon={<PaperClipOutlined />}>
                    Adjuntar archivo o imagen
                  </Boton>
                </Upload>
              </Form.Item>
            </>
          )}
          <div className="justify-content-end">
            <Boton type="primary" htmlType="submit" disabled={isButtonDisabled}>
              Anexar Eventuales
            </Boton>
          </div>
        </Form>
      </Modal>
    </>
  );
}

ModalRegistrarEventuales.propTypes = {
  modaldEventWeekend: PropTypes.bool,
  handleEventualWeekend: PropTypes.func,
  finishFromEventWeekend: PropTypes.func,
  expenseDetail: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  companyEventual: PropTypes.array,
  setEventualsPay: PropTypes.func,
  setTotalEventuals: PropTypes.func,
  eventualsPay: PropTypes.array,
  totalEventuals: PropTypes.number,
  setArchive: PropTypes.func,
  setIsFileRemoved: PropTypes.func,
  isButtonDisabled: PropTypes.bool
};
