import React, { useEffect, useState, useLayoutEffect } from 'react';
import TablaEgresoEfectivo from '../Components/Organisms/TablaEgresoEfectivo';
import { ArrowLeftOutlined, FileExcelOutlined } from '@ant-design/icons';
import clienteAxios from '../Utils/clienteAxios';
import ReactHtmlTableToExcel from 'react-html-table-to-excel';
import { useNavigate } from 'react-router-dom';

const HistorialEgresoGastado = () => {
  const [egressHistory, setEgressHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useLayoutEffect(() => {
    document.querySelector('.ant-table-content table').id = 'table-to-xls';
  }, []);

  useEffect(() => {
    clienteAxios
      .get(`/caja-chica/estimate/return/history`)
      .then((response) => {
        let data = response.data.history;
        setEgressHistory(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <>
      <div className="title-content-between">
        <h1 className="mb-2 mr-5">
          <ArrowLeftOutlined className="arrow-size" onClick={() => navigate(-1)} />
          HISTORIAL DE EGRESO DE EFECTIVO
        </h1>
        <div className="mb-2 flex justify-content-end">
          <ReactHtmlTableToExcel
            id="test-table-xls-button"
            className="download-table-xls-button"
            table="table-to-xls"
            filename="Historial Egreso Efectivo"
            sheet="Historial Egreso Efectivo"
            buttonText={
              <>
                <FileExcelOutlined className="mr-2" />
                Exportar en Excel
              </>
            }
          />
        </div>
      </div>

      <div className="flex-row flex-wrap mt-1">
        <TablaEgresoEfectivo data={egressHistory} loading={loading} />
      </div>
    </>
  );
};

export default HistorialEgresoGastado;
