import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Boton from '../Components/Atoms/Boton';
import TablaSolicitudesCompra from '../Components/Organisms/TablaSolicitudesCompra';
import { ReconciliationOutlined } from '@ant-design/icons';
import clienteAxios from '../Utils/clienteAxios';
import decodeToken from '../Utils/decodeToken';
import { Tabs, Alert } from 'antd';
import Swal from 'sweetalert2';

const SolicitudesGasto = () => {
  const [requestsManager, setRequestsManager] = useState([]);
  const [adminRequests, setAdminRequests] = useState([]);
  const [myRequests, setMyRequests] = useState([]);
  const [shopping, setShopping] = useState([]);
  const [loading, setLoading] = useState(true);
  let decodedToken = decodeToken(localStorage.getItem('token'));

  const allowedRoles = [
    'equipo_administracion',
    'equipo_almacen',
    'equipo_calidad',
    'equipo_compras',
    'equipo_direccion',
    'equipo_diseno_operativo',
    'equipo_importaciones',
    'equipo_logistica',
    'equipo_mesa_control',
    'equipo_produccion',
    'equipo_recursos_humanos',
    'equipo_ti',
    'equipo_ventas_bh',
    'equipo_ventas_pl',
    'sin_definir',
    'Super_Admin',
    'marketing_pl'
  ];

  const userRole = decodedToken?.role?.name;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [managerResponse, myOrdersResponse, shoppingResponse, adminResponse] =
          await Promise.all([
            clienteAxios.get('caja-chica/solicitudes-de-compra/por-departamento/ver'),
            clienteAxios.get('/caja-chica/mis-ordenes'),
            clienteAxios.get('/caja-chica/ordenes-comprador'),
            clienteAxios.get('/caja-chica/administrador/solicitudes-de-compra/ver')
          ]);
        setRequestsManager(managerResponse.data.spents.reverse());
        setMyRequests(myOrdersResponse.data.spents.reverse());
        setShopping(shoppingResponse.data.spents.reverse());
        setAdminRequests(adminResponse.data.spents.reverse());
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: error.response?.data?.message,
          confirmButtonText: 'Aceptar'
        });
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <div className="title-content-between">
        <h1 className="mb-2 mr-5">Solicitudes de gasto</h1>
        {userRole === 'administrator' ||
        userRole === 'Super_Admin' ||
        userRole === 'caja_chica' ||
        userRole === 'Adquisiciones' ? (
          <Link to="/reporte-general">
            <Boton className="mb-2" type="secondary" icon={<ReconciliationOutlined />}>
              Reporte General
            </Boton>
          </Link>
        ) : null}
        <Link to="/nueva-solicitud">
          <Boton type="primary">Nueva Solicitud</Boton>
        </Link>
      </div>
      {userRole === 'administrator' || userRole === 'Super_Admin' ? (
        <div>
          <Tabs
            defaultActiveKey="1"
            items={[
              {
                key: '1',
                label: `Solicitudes Por Aprobar`,
                children: (
                  <>
                    <Alert
                      message="En este apartado se muestran las 'SOLICITUDES DE GASTO' que ya fueron aprobadas o no por el 'JEFE DIRECTO'."
                      type="info"
                      showIcon
                      closable
                    />
                    <TablaSolicitudesCompra
                      data={adminRequests}
                      loading={loading}
                      setLoading={setLoading}
                    />
                  </>
                )
              },
              {
                key: '2',
                label: `Mis Solicitudes`,
                children: (
                  <>
                    <TablaSolicitudesCompra data={myRequests} loading={loading} />
                  </>
                )
              }
            ]}
          />
        </div>
      ) : allowedRoles.includes(userRole) ? (
        <div>
          <TablaSolicitudesCompra data={myRequests} loading={loading} />
        </div>
      ) : userRole === 'caja_chica' || userRole === 'Adquisiciones' ? (
        <div>
          <Tabs
            defaultActiveKey="1"
            items={[
              {
                key: '1',
                label: `Solicitudes De Adquisición`,
                children: (
                  <>
                    <Alert
                      message="En este apartado solamente se mostrarán las  'SOLICITUDES DE GASTO'  que ya fueron aprobadas por el Administrador para proceder a su compra."
                      type="info"
                      showIcon
                      closable
                    />
                    <TablaSolicitudesCompra data={shopping} loading={loading} />
                  </>
                )
              },
              {
                key: '2',
                label: `Mis Solicitudes`,
                children: (
                  <>
                    <TablaSolicitudesCompra data={myRequests} loading={loading} />
                  </>
                )
              }
            ]}
          />
        </div>
      ) : userRole === 'manager' ? (
        <div>
          <Tabs
            defaultActiveKey="1"
            items={[
              {
                key: '1',
                label: `Solicitudes Por Aprobar`,
                children: (
                  <>
                    <Alert
                      message="En este apartado solamente se mostrarán las 'SOLICITUDES DE GASTO' que tienes de tú área."
                      type="info"
                      showIcon
                      closable
                    />
                    <TablaSolicitudesCompra data={requestsManager} loading={loading} />
                  </>
                )
              },
              {
                key: '2',
                label: `Mis Solicitudes`,
                children: (
                  <>
                    <TablaSolicitudesCompra data={myRequests} loading={loading} />
                  </>
                )
              }
            ]}
          />
        </div>
      ) : null}
    </>
  );
};
export default SolicitudesGasto;
