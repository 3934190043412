import { Input, Space, Table } from 'antd';
import React, { useState, useRef } from 'react';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import localeObjectTable from '../../Utils/localeObjectTable';
import PropTypes from 'prop-types';
import StatusBar from './StatusBar';
import Boton from '../Atoms/Boton';
import { Link } from 'react-router-dom';
import currencyFormatter from '../../Utils/currencyFormatter';
import { DatePicker } from 'antd';
import moment from 'moment';
import es from 'antd/lib/date-picker/locale/es_ES';
import decodeToken from '../../Utils/decodeToken';

const TablaSolicitudesCompra = ({ data, loading }) => {
  const { RangePicker } = DatePicker;
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  let decodedToken = decodeToken(localStorage.getItem('token'));
  const isAdmin = decodedToken?.role?.name === 'administrator';
  const isSuperAdmin = decodedToken?.role?.name === 'Super_Admin';
  const isShopping = decodedToken?.role?.name === 'caja_chica';
  const isAdquision = decodedToken?.role?.name === 'Adquisiciones';

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: '0 8px 8px 8px'
        }}
        onKeyDown={(e) => e.stopPropagation()}>
        <Boton
          type="link"
          size="small"
          onClick={() => {
            close();
          }}
          className="button-close-search-filter-table">
          X
        </Boton>
        <Input
          ref={searchInput}
          placeholder={`Buscar`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block'
          }}
        />
        <Space>
          <Boton
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small">
            Buscar
          </Boton>
          <Boton onClick={() => clearFilters && handleReset(clearFilters)} size="small" danger>
            Limpiar
          </Boton>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      )
  });

  const tableSolicitudes = [
    {
      title: 'ID SOLICITUD',
      dataIndex: 'id',
      key: 'id',
      fixed: 'left',
      ...getColumnSearchProps('id'),
      sorter: (a, b) => a.id - b.id,
      sortDirections: ['ascend', 'descend'],
      render: (text) => <Link to={`/detalle-gasto/${text}`}>GTO-{text}</Link>
    },
    isAdmin || isSuperAdmin || isShopping || isAdquision
      ? {
          title: 'NOMBRE SOLICITANTE',
          dataIndex: 'user_name',
          key: 'user_name',
          ...getColumnSearchProps('user_name'),
          sorter: (a, b) => a.user_name.localeCompare(b.user_name),
          sortDirections: ['ascend', 'descend']
        }
      : null,
    {
      title: 'FECHA DE CREACIÓN',
      dataIndex: 'created_at',
      key: 'created_at',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
        <div
          style={{
            padding: '0 8px 8px 8px'
          }}
          onKeyDown={(e) => e.stopPropagation()}>
          <Boton
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
            className="button-close-search-filter-table">
            X
          </Boton>
          <RangePicker
            locale={es}
            placeholder={['Fecha inicial', 'Fecha final']}
            value={selectedKeys[0]}
            onChange={(dates) => {
              setSelectedKeys([dates]);
            }}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Boton
              type="primary"
              onClick={() => {
                confirm();
              }}
              icon={<SearchOutlined />}
              size="small">
              Buscar
            </Boton>
            <Boton onClick={clearFilters} size="small" danger>
              Limpiar
            </Boton>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{
            color: filtered ? '#1890ff' : undefined
          }}
        />
      ),
      onFilter: (value, record) => {
        if (!value) return true;
        const start = value[0].startOf('day').toDate(); // Convertir a objeto Date
        const end = value[1].endOf('day').toDate(); // Convertir a objeto Date
        const created = moment(record.created_at, 'DD-MM-YYYY').toDate(); // Convertir a objeto Date
        return created >= start && created <= end;
      },
      sorter: (a, b) => moment(a.created_at, 'DD-MM-YYYY') - moment(b.created_at, 'DD-MM-YYYY'),
      sortDirections: ['ascend', 'descend']
    },
    isAdmin || isSuperAdmin
      ? {
          title: 'FECHA DE COMPRA',
          dataIndex: 'creation_date',
          key: 'creation_date',
          filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
              style={{
                padding: '0 8px 8px 8px'
              }}
              onKeyDown={(e) => e.stopPropagation()}>
              <Boton
                type="link"
                size="small"
                onClick={() => {
                  close();
                }}
                className="button-close-search-filter-table">
                X
              </Boton>
              <RangePicker
                locale={es}
                placeholder={['Fecha inicial', 'Fecha final']}
                value={selectedKeys[0]}
                onChange={(dates) => {
                  setSelectedKeys([dates]);
                }}
                style={{ marginBottom: 8, display: 'block' }}
              />
              <Space>
                <Boton
                  type="primary"
                  onClick={() => {
                    confirm();
                  }}
                  icon={<SearchOutlined />}
                  size="small">
                  Buscar
                </Boton>
                <Boton onClick={clearFilters} size="small" danger>
                  Limpiar
                </Boton>
              </Space>
            </div>
          ),
          filterIcon: (filtered) => (
            <SearchOutlined
              style={{
                color: filtered ? '#1890ff' : undefined
              }}
            />
          ),
          onFilter: (value, record) => {
            if (!value) return true;
            const start = value[0].startOf('day').toDate(); // Convertir a objeto Date
            const end = value[1].endOf('day').toDate(); // Convertir a objeto Date
            const created = moment(record.creation_date, 'DD-MM-YYYY').toDate(); // Convertir a objeto Date
            return created >= start && created <= end;
          },
          sorter: (a, b) =>
            moment(a.creation_date, 'DD-MM-YYYY') - moment(b.creation_date, 'DD-MM-YYYY'),
          sortDirections: ['ascend', 'descend']
        }
      : null,
    {
      title: 'ESTADO',
      dataIndex: 'purchase_table_name',
      key: 'purchase_table_name'
    },
    {
      title: 'CENTRO DE COSTOS',
      dataIndex: 'center_name',
      key: 'center_name',
      ...getColumnSearchProps('center_name'),
      sorter: (a, b) => a.center_name.localeCompare(b.center_name),
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'CONCEPTO',
      dataIndex: 'spent_name',
      key: 'spent_name',
      ...getColumnSearchProps('spent_name'),
      sorter: (a, b) => a.spent_name.localeCompare(b.spent_name),
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'AUTORIZACIÓN JEFE DIRECTO',
      dataIndex: 'approved_by',
      key: 'approved_by',
      ...getColumnSearchProps('approved_by'),
      sorter: (a, b) => a.approved_by.localeCompare(b.approved_by),
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'AUTORIZACIÓN ADMINISTRADOR',
      dataIndex: 'admin_approved',
      key: 'admin_approved',
      ...getColumnSearchProps('admin_approved'),
      sorter: (a, b) => a.admin_approved.localeCompare(b.admin_approved),
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'TOTAL',
      dataIndex: 'total',
      key: 'total',
      ...getColumnSearchProps('total'),
      sorter: (a, b) => a.total - b.total,
      sortDirections: ['ascend', 'descend'],
      render: (text) => currencyFormatter(text)
    }
  ].filter(Boolean);

  const newDataSOL = data?.map((item) => {
    return {
      key: item?.id,
      id: item?.id,
      created_at: item?.created_at,
      creation_date: item?.creation_date,
      center_name: item?.center[0].center_name,
      spent_name: item?.spent[0].spent_name,
      approved_by: item?.approved_by ? item?.approved_by : 'PENDIENTE',
      admin_approved: item?.admin_approved ? item?.admin_approved : 'PENDIENTE',
      total: item.total,
      user_name: item?.user_name,
      purchase_table_name: (
        <>
          <StatusBar
            status={item?.purchase_table_name}
            substatus={item?.type_status}
            approved={item?.approved_status}
          />
        </>
      ),
      verdetalle: (
        <Boton type="secondary" icon={<PlusOutlined />}>
          Ver Detalle
        </Boton>
      )
    };
  });
  return (
    <>
      <Table
        loading={loading}
        columns={tableSolicitudes}
        dataSource={newDataSOL}
        pagination={{
          position: ['bottomCenter'],
          defaultPageSize: 20,
          pageSizeOptions: [20, 40, 60, 80, 100],
          total: data.total,
          showTotal: (total, range) =>
            `Mostrando solicitudes del ${range[0]} al ${range[1]} de un total de ${total}`
        }}
        className="mt-4 mb-4"
        locale={localeObjectTable}
        scroll={{
          x: true
        }}
      />
    </>
  );
};

TablaSolicitudesCompra.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool
};
export default TablaSolicitudesCompra;
