import React from 'react';
import PropTypes from 'prop-types';
import Firma from '../Molecules/Firma';
import { Form, Input } from 'antd';

function CrearFirmas({
  nombreElaboro = '',
  nombreReviso = '',
  firmaReviso,
  setFirmaReviso,
  firmaElaboro,
  setFirmaElaboro,
  setNombreReviso,
  type
}) {
  return (
    <>
      <div className="flex flex-wrap justify-content-around width-100">
        <div className="flex-column align-items-center text-align-center justify-content-evenly mb-sm-4">
          <div style={{ marginBottom: '3.5%' }}>
            <strong>ELABORÓ: </strong>
          </div>
          <div style={{ marginBottom: '3.5%' }}>
            <span>{nombreElaboro || 'No hay información'}</span>
          </div>
          <Form.Item
            name="firmaElaboro"
            rules={[
              {
                required: true,
                validator: () => {
                  if (firmaElaboro !== undefined && firmaElaboro !== '') {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Por favor, ingresa tu firma!'));
                }
              }
            ]}>
            <Firma firma={firmaElaboro} setFirma={setFirmaElaboro} />
          </Form.Item>
        </div>
        <>
          <div className="flex-column align-items-center text-align-center">
            <div>
              <strong>{type === 'remision' ? 'RECIBIÓ:' : 'REVISÓ:'}</strong>
              <Form.Item
                name="nombreReviso"
                rules={[
                  {
                    required: true,
                    message: 'Este campo es requerido'
                  }
                ]}
                style={{ marginBottom: '0.5rem' }}>
                <Input
                  placeholder="Nombre de quien revisó"
                  onChange={(e) => setNombreReviso(e.target.value)}
                  value={nombreReviso}
                  style={{ width: '90%' }}
                />
              </Form.Item>
            </div>

            <Form.Item
              name="firmaReviso"
              rules={[
                {
                  required: true,
                  validator: () => {
                    if (firmaReviso !== undefined && firmaReviso !== '') {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error('Por favor, pide a la persona que te revisó que firme!')
                    );
                  }
                }
              ]}>
              <Firma firma={firmaReviso} setFirma={setFirmaReviso} />
            </Form.Item>
          </div>
        </>
      </div>
    </>
  );
}

CrearFirmas.defaultProps = {
  type: 'general'
};

CrearFirmas.propTypes = {
  nombreElaboro: PropTypes.string,
  nombreReviso: PropTypes.string,
  setNombreReviso: PropTypes.func,
  firmaReviso: PropTypes.string,
  setFirmaReviso: PropTypes.func,
  firmaElaboro: PropTypes.string,
  setFirmaElaboro: PropTypes.func,
  type: PropTypes.oneOf(['remision', 'general'])
};

export default CrearFirmas;
