import React from 'react';
// import Anime from 'animejs';
import { ConfigProvider, Steps } from 'antd';
import PropTypes from 'prop-types';

const circuleSteps = (className, number) => {
  return (
    <div className={className}>
      <span className="circulo_step_number">{number}</span>
    </div>
  );
};

const Status2 = ({ status }) => {
  const stepsColors = status?.reduce((acc, curr) => {
    acc[curr?.slug] =
      curr?.visible === 1
        ? 'finish'
        : curr?.visible === 0
        ? 'process'
        : curr?.visible === 3
        ? 'finishProcess'
        : curr?.visible === 4
        ? 'entregaCliente'
        : 'wait';
    return acc;
  }, {});

  const statusSteps = {
    'pedido-confirmado': 0,
    'oc-ot-creadas': 1,
    'recibido-en-almacen-pl': 2,
    'enviado a maquila': 3,
    'recibido-en-almacen-pm': 4,
    'conteo-del-producto': 5,
    'inspeccion-de-producto': 6,
    'proceso-de-entrega-al-cliente': 7,
    'entrega-al-cliente': 8
  };

  const currentStep =
    statusSteps[status?.[3]?.slug || status?.[2]?.slug || status?.[1]?.slug] ||
    statusSteps[status?.[0]?.slug];

  const stpes = [
    {
      title: 'Pedido confirmado',
      // status:
      //   stepsColors['pedido-confirmado'] === 'finish'
      //     ? 'finish'
      //     : stepsColors['pedido-confirmado'] === 'process'
      //     ? 'process'
      //     : 'wait',
      icon: <span>{circuleSteps('circulo_step_success', '✔')}</span>

      // stepsColors['pedido-confirmado'] === 'finish' ? (
      //   <span>{circuleSteps('circulo_step_success', '✔')}</span>
      // ) : stepsColors['pedido-confirmado'] === 'process' ? (
      //   <span>{circuleSteps('circulo_step_process', 1)}</span>
      // ) : null
    },
    {
      title: 'OC / OT Y CheckList',
      status:
        stepsColors['oc-ot-creadas'] === 'finish'
          ? 'finish'
          : stepsColors['oc-ot-creadas'] === 'process'
          ? 'process'
          : 'wait',
      icon:
        stepsColors['oc-ot-creadas'] === 'finish' ? (
          <span>{circuleSteps('circulo_step_success', '✔')}</span>
        ) : stepsColors['oc-ot-creadas'] === 'process' ? (
          <span>{circuleSteps('circulo_step_process', 2)}</span>
        ) : null
    },
    {
      title: 'Recibido en almacén PL',
      status:
        stepsColors['recibido-en-almacen-pl'] === 'finish'
          ? 'finish'
          : stepsColors['recibido-en-almacen-pl'] === 'process'
          ? 'process'
          : 'wait',
      icon:
        stepsColors['recibido-en-almacen-pl'] === 'finish' ? (
          <span>{circuleSteps('circulo_step_success', '✔')}</span>
        ) : stepsColors['recibido-en-almacen-pl'] === 'process' ? (
          <span>{circuleSteps('circulo_step_process', 3)}</span>
        ) : stepsColors['recibido-en-almacen-pl'] === 'finishProcess' ? (
          <span>{circuleSteps('circulo_step_succesProcess', '✔')}</span>
        ) : stepsColors['enviado a maquila'] === 'finish' ||
          stepsColors['enviado a maquila'] === 'process' ||
          stepsColors['enviado a maquila'] === 'finishProcess' ? (
          <span>{circuleSteps('circulo_step_error', 'X')}</span>
        ) : stepsColors['recibido-en-almacen-pm'] === 'finish' ||
          stepsColors['recibido-en-almacen-pm'] === 'process' ||
          stepsColors['recibido-en-almacen-pm'] === 'finishProcess' ? (
          <span>{circuleSteps('circulo_step_error', 'X')}</span>
        ) : null
    },
    {
      title: 'Enviado a maquila',
      status:
        stepsColors['enviado a maquila'] === 'finish'
          ? 'finish'
          : stepsColors['enviado a maquila'] === 'process'
          ? 'process'
          : 'wait',
      icon:
        stepsColors['enviado a maquila'] === 'finish' ? (
          <span>{circuleSteps('circulo_step_success', '✔')}</span>
        ) : stepsColors['enviado a maquila'] === 'process' ? (
          <span>{circuleSteps('circulo_step_process', 4)}</span>
        ) : stepsColors['enviado a maquila'] === 'finishProcess' ? (
          <span>{circuleSteps('circulo_step_succesProcess', '✔')}</span>
        ) : stepsColors['recibido-en-almacen-pm'] === 'finish' ||
          stepsColors['recibido-en-almacen-pm'] === 'process' ||
          stepsColors['recibido-en-almacen-pm'] === 'finishProcess' ? (
          <span>{circuleSteps('circulo_step_error', 'X')}</span>
        ) : stepsColors['conteo-del-producto'] === 'finish' ||
          stepsColors['conteo-del-producto'] === 'process' ? (
          <span>{circuleSteps('circulo_step_error', 'X')}</span>
        ) : stepsColors['inspeccion-de-producto'] === 'finish' ||
          stepsColors['inspeccion-de-producto'] === 'process' ? (
          <span>{circuleSteps('circulo_step_error', 'X')}</span>
        ) : stepsColors['proceso-de-entrega-al-cliente'] === 'finish' ||
          stepsColors['proceso-de-entrega-al-cliente'] === 'process' ? (
          <span>{circuleSteps('circulo_step_error', 'X')}</span>
        ) : stepsColors['entrega-al-cliente'] === 'finish' ||
          stepsColors['entrega-al-cliente'] === 'process' ? (
          <span>{circuleSteps('circulo_step_error', 'X')}</span>
        ) : null
    },
    {
      title: 'Recibido en almacén PM',
      status:
        stepsColors['recibido-en-almacen-pm'] === 'finish'
          ? 'finish'
          : stepsColors['recibido-en-almacen-pm'] === 'process'
          ? 'process'
          : 'wait',
      icon:
        stepsColors['recibido-en-almacen-pm'] === 'finish' ? (
          <span>{circuleSteps('circulo_step_success', '✔')}</span>
        ) : stepsColors['recibido-en-almacen-pm'] === 'process' ? (
          <span>{circuleSteps('circulo_step_process', 5)}</span>
        ) : stepsColors['recibido-en-almacen-pm'] === 'finishProcess' ? (
          <span>{circuleSteps('circulo_step_succesProcess', '✔')}</span>
        ) : stepsColors['conteo-del-producto'] === 'finish' ||
          stepsColors['conteo-del-producto'] === 'process' ? (
          <span>{circuleSteps('circulo_step_error', 'X')}</span>
        ) : stepsColors['inspeccion-de-producto'] === 'finish' ||
          stepsColors['inspeccion-de-producto'] === 'process' ? (
          <span>{circuleSteps('circulo_step_error', 'X')}</span>
        ) : stepsColors['proceso-de-entrega-al-cliente'] === 'finish' ||
          stepsColors['proceso-de-entrega-al-cliente'] === 'process' ? (
          <span>{circuleSteps('circulo_step_error', 'X')}</span>
        ) : stepsColors['entrega-al-cliente'] === 'finish' ||
          stepsColors['entrega-al-cliente'] === 'process' ? (
          <span>{circuleSteps('circulo_step_error', 'X')}</span>
        ) : null
    },
    {
      title: 'Conteo del producto',
      status:
        stepsColors['conteo-del-producto'] === 'finish'
          ? 'finish'
          : stepsColors['conteo-del-producto'] === 'process'
          ? 'process'
          : 'wait',
      icon:
        stepsColors['conteo-del-producto'] === 'finish' ? (
          <span>{circuleSteps('circulo_step_success', '✔')}</span>
        ) : stepsColors['conteo-del-producto'] === 'process' ? (
          <span>{circuleSteps('circulo_step_process', 6)}</span>
        ) : stepsColors['inspeccion-de-producto'] === 'finish' ||
          stepsColors['inspeccion-de-producto'] === 'process' ? (
          <span>{circuleSteps('circulo_step_error', 'X')}</span>
        ) : stepsColors['proceso-de-entrega-al-cliente'] === 'finish' ||
          stepsColors['proceso-de-entrega-al-cliente'] === 'process' ? (
          <span>{circuleSteps('circulo_step_error', 'X')}</span>
        ) : stepsColors['entrega-al-cliente'] === 'finish' ||
          stepsColors['entrega-al-cliente'] === 'process' ? (
          <span>{circuleSteps('circulo_step_error', 'X')}</span>
        ) : null
    },
    {
      title: 'Inspección de producto',
      status:
        stepsColors['inspeccion-de-producto'] === 'finish'
          ? 'finish'
          : stepsColors['inspeccion-de-producto'] === 'process'
          ? 'process'
          : 'wait',
      icon:
        stepsColors['inspeccion-de-producto'] === 'finish' ? (
          <span>{circuleSteps('circulo_step_success', '✔')}</span>
        ) : stepsColors['inspeccion-de-producto'] === 'process' ? (
          <span>{circuleSteps('circulo_step_process', 7)}</span>
        ) : stepsColors['proceso-de-entrega-al-cliente'] === 'finish' ||
          stepsColors['proceso-de-entrega-al-cliente'] === 'process' ? (
          <span>{circuleSteps('circulo_step_error', 'X')}</span>
        ) : stepsColors['entrega-al-cliente'] === 'finish' ||
          stepsColors['entrega-al-cliente'] === 'process' ? (
          <span>{circuleSteps('circulo_step_error', 'X')}</span>
        ) : null
    },
    {
      title: 'Programado en ruta cliente',
      status:
        stepsColors['proceso-de-entrega-al-cliente'] === 'finish'
          ? 'finish'
          : stepsColors['proceso-de-entrega-al-cliente'] === 'process'
          ? 'process'
          : 'wait',
      icon:
        stepsColors['proceso-de-entrega-al-cliente'] === 'finish' ? (
          <span>{circuleSteps('circulo_step_success', '✔')}</span>
        ) : stepsColors['proceso-de-entrega-al-cliente'] === 'process' ? (
          <span>{circuleSteps('circulo_step_process', 8)}</span>
        ) : null
    },
    {
      title: 'Entrega al cliente',
      status:
        stepsColors['entrega-al-cliente'] === 'finish'
          ? 'finish'
          : stepsColors['entrega-al-cliente'] === 'process'
          ? 'process'
          : 'wait',
      icon:
        stepsColors['entrega-al-cliente'] === 'finish' ? (
          <span>{circuleSteps('circulo_step_success', '✔')}</span>
        ) : stepsColors['entrega-al-cliente'] === 'process' ? (
          <span>{circuleSteps('circulo_step_process', 9)}</span>
        ) : stepsColors['entrega-al-cliente'] === 'entregaCliente' ? (
          <span>{circuleSteps('circulo_step_entregaCliente', '✔')}</span>
        ) : null
    }
  ];

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorSplit: '#f4f9ff !important'
          }
        }}>
        <Steps
          size="small"
          className="mt-4 mb-2"
          labelPlacement="vertical"
          current={currentStep}
          items={stpes}></Steps>
      </ConfigProvider>
    </>
  );
};

Status2.propTypes = {
  status: PropTypes.array
};

export default Status2;
