import { Alert, Card, Spin, Input } from 'antd';
import Meta from 'antd/es/card/Meta';
import Playbpms from '../Assets/Images/Playbpms.png';
import ModalVideos from '../Components/Molecules/ModalVideos';
import { useEffect, useState } from 'react';
import clienteAxios from '../Utils/clienteAxios';
import decodeToken from '../Utils/decodeToken';
import { SearchOutlined } from '@ant-design/icons';

const ayuda = () => {
  const [modalVideos, setModalVideos] = useState();
  const [media, setMedia] = useState();
  const [dataVideos, setDataVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  let decodedToken = decodeToken(localStorage.getItem('token'));
  console.log(decodedToken?.role?.name);

  useEffect(() => {
    clienteAxios
      .get('/video')
      .then((response) => {
        setLoading(false);
        setDataVideos(response?.data?.videos);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [loading]);

  const filteredVideos = searchTerm
    ? dataVideos.filter((video) => {
        const requiredRole = '*';
        const decodedRole = decodedToken?.role?.name || '';
        return (
          (video.rol.includes(requiredRole) || video.rol.includes(decodedRole)) &&
          video.nombre.toLowerCase().includes(searchTerm.toLowerCase())
        );
      })
    : dataVideos;

  return (
    <>
      <div>
        <h1>capacitación</h1>
      </div>
      <Alert
        className="mt-3"
        message="Los videos se muestran de acuerdo a los permisos asignados a cada usuario. Esto significa que cada usuario tiene acceso a funciones específicas de acuerdo a su nivel de autorización."
        type="info"
        showIcon
        closable
      />
      <Input
        className="mt-3"
        placeholder="Buscar por nombre de video"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
        suffix={<SearchOutlined />}
        allowClear
      />

      <Spin className="mt-5" spinning={loading} tip="Cargando...">
        <div className="flex flex-wrap mt-3" style={{ justifyContent: 'space-between' }}>
          {filteredVideos.map((video, index) => {
            return (
              <Card
                hoverable
                key={index}
                className="mb-4"
                style={{
                  width: 380
                }}
                onClick={() => {
                  setMedia(video);
                  setModalVideos(true);
                }}
                size="small"
                cover={<img alt="example" src={Playbpms} />}>
                <Meta title={video?.nombre} />
              </Card>
            );
          })}
        </div>
      </Spin>

      <ModalVideos
        media={media}
        setMedia={setMedia}
        setModalVideos={setModalVideos}
        modalVideos={modalVideos}
        loading={loading}
        setLoading={setLoading}
      />
    </>
  );
};
export default ayuda;
