import React, { useEffect, useState, useLayoutEffect } from 'react';
import TablaEmpresas from '../Components/Organisms/TablaEmpresas';
import { ArrowLeftOutlined, IdcardOutlined, FileExcelOutlined } from '@ant-design/icons';
import clienteAxios from '../Utils/clienteAxios';
import ReactHtmlTableToExcel from 'react-html-table-to-excel';
import { useNavigate } from 'react-router-dom';
import Boton from '../Components/Atoms/Boton';
import { Form, Input } from 'antd';
import Swal from 'sweetalert2';
import Modal from '../Components/Atoms/Modal';

const HistorialEmpresasEventuales = () => {
  const [historyCompanies, setHistoryCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const [companyName, setCompanyName] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [reloadAddCompany, setReloadAddCompany] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useLayoutEffect(() => {
    document.querySelector('.ant-table-content table').id = 'table-to-xls';
  }, []);

  useEffect(() => {
    clienteAxios
      .get(`/caja-chica/company`)
      .then((response) => {
        let data = response.data.companies;
        data.reverse();
        setHistoryCompanies(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [loading]);

  const handleOk = () => {
    let existe = historyCompanies.find((empresa) => empresa.name === companyName);
    if (existe) {
      Swal.fire({
        icon: 'error',
        title: 'Cuidado!',
        text: 'La empresa ya existe',
        showCancelButton: false,
        confirmButtonText: 'ACEPTAR'
      });
      return;
    }
    setIsSubmitting(true);
    clienteAxios
      .post(`/caja-chica/newcompany`, { name: companyName })
      .then(() => {
        setIsSubmitting(false);
        handleCancel();
        setReloadAddCompany(!reloadAddCompany);
        Swal.fire({
          icon: 'success',
          title: 'Empresa agregada correctamente!',
          showCancelButton: false,
          confirmButtonText: 'LISTO'
        }).then(() => {
          setLoading(true);
        });
      })
      .catch((error) => {
        console.error(error);
        setIsSubmitting(false);
        Swal.fire({
          icon: 'error',
          title: 'Error al agregar la empresa!',
          showCancelButton: false,
          confirmButtonText: 'INTENTAR DE NUEVO'
        });
      });
  };

  return (
    <>
      <div className="title-content-between">
        <h1 className="mb-2 mr-5">
          <ArrowLeftOutlined className="arrow-size" onClick={() => navigate(-1)} />
          EMPRESAS EVENTUALES
        </h1>
        <Boton className="mb-3" type="secondary" icon={<IdcardOutlined />} onClick={showModal}>
          Agregar Empresa
        </Boton>
        <div>
          <ReactHtmlTableToExcel
            id="test-table-xls-button"
            className="download-table-xls-button"
            table="table-to-xls"
            filename="Empresas Eventuales"
            sheet="Empresas Eventuales"
            buttonText={
              <>
                <FileExcelOutlined className="mr-2" />
                Exportar en Excel
              </>
            }
          />
        </div>
      </div>

      <div className="flex-row flex-wrap mt-1">
        <TablaEmpresas data={historyCompanies} setLoading={setLoading} loading={loading} />
      </div>

      <Modal
        title="AGREGAR EMPRESA"
        centered
        key={reloadAddCompany}
        open={isModalOpen}
        onCancel={handleCancel}
        onOk={handleOk}
        confirmLoading={isSubmitting}>
        <Form className="mt-4">
          <Form.Item
            rules={[{ required: true, message: 'Por favor ingrese el nombre de la empresa' }]}
            name="nombre_empresa"
            label="Nombre de la Empresa"
            onChange={(e) => setCompanyName(e.target.value)}>
            <Input type="text" placeholder="Nombre de la Empresa" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default HistorialEmpresasEventuales;
