import React from 'react';
import { Steps } from 'antd';
import PropTypes from 'prop-types';

export default function StatusBar({ status, substatus, approved }) {
  let localPosition =
    status.trim() === 'pedido-en-proceso'
      ? 0
      : status.trim() === 'pedido-en-compra'
      ? 1
      : status.trim() === 'pedido-entregado' || status.trim() === 'pedido-devolución'
      ? 2
      : status.trim() === 'pedido-recibido'
      ? 3
      : 0;

  let localStatus = substatus === 'normal' ? 'process' : 'error';

  return (
    <div>
      <Steps
        current={localPosition}
        status={
          approved.trim() === 'rechazada' || approved.trim() === 'devolución'
            ? 'error'
            : localPosition === 3
            ? 'finish'
            : localStatus
        }
        size="small"
        className="mt-3 ml-1 mb-3 mr-1"
        labelPlacement="horizontal"
        responsive={false}
        items={[
          {
            title: 'En Proceso'
          },
          {
            title: 'Adquisición'
          },
          {
            title: 'Entregado'
          },
          {
            title: 'Recibido'
          }
        ]}
      />
    </div>
  );
}

StatusBar.propTypes = {
  status: PropTypes.string,
  substatus: PropTypes.string,
  approved: PropTypes.string
};
