import { Form, Select, Spin, InputNumber } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import clienteAxios from '../Utils/clienteAxios';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import decodeToken from '../Utils/decodeToken';
import Boton from '../Components/Atoms/Boton';
import Swal from 'sweetalert2';
import { photosEndpoint } from '../Constants/apiEndpoint';
import ModalRegistrarEventuales from '../Components/Organisms/ModalRegistrarEventuales';
import ModalDatosEvetuales from '../Components/Organisms/ModalDatosEvetuales';
import ModalRegresarExcedente from '../Components/Organisms/ModalRegresarExcedente';
import ModalActualizarMonto from '../Components/Organisms/ModalActualizarMonto';
import FormDetalleGasto from '../Components/Organisms/FormDetalleGasto';
import currencyFormatter from '../Utils/currencyFormatter';

const DetalleGasto = () => {
  const [expenseDetail, setExpenseDetail] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isModalOpenAmount, setIsModalOpenAmount] = useState(false);
  const [isModalOpenExcess, setIsModalOpenExcess] = useState(false);
  const [isModalOpenEventual, setIsModalOpenEventual] = useState(false);
  const [amountUpdate, setAmountUpdate] = useState(0);
  const [quantitySurplus, setQuantitySurplus] = useState(0);
  const [descriptionSurplus, setDescriptionSurplus] = useState('');
  const [archive, setArchive] = useState(null);
  const [isFileRemoved, setIsFileRemoved] = useState(false);
  const [payment, setPayment] = useState(expenseDetail?.payment_method);
  const [modaldEventWeekend, setModaldEventWeekend] = useState(false);
  const [botonPresionado, setBotonPresionado] = useState(null);
  const [companyEventual, setCompanyEventual] = useState([]);
  const [totalEventuals, setTotalEventuals] = useState(0);
  const [eventualsPay, setEventualsPay] = useState([]);
  let decodedToken = decodeToken(localStorage.getItem('token'));
  const isAdmin = decodedToken?.role?.name === 'administrator';
  const isSuperAdmin = decodedToken?.role?.name === 'Super_Admin';
  const isShopping = decodedToken?.role?.name === 'caja_chica';
  const isAdquision = decodedToken?.role?.name === 'Adquisiciones';
  const isUser = decodedToken?.user?.name === expenseDetail?.user_name;
  const isDevolution = expenseDetail.approved_status === 'devolución';
  const isNormal = expenseDetail.type_status === 'normal';
  const isReceived = expenseDetail?.purchase_status === 'Recibido';
  const isCancel = expenseDetail?.type_status !== 'cancelado';
  const isDevolutionReturn = expenseDetail.purchase_table_name === 'pedido-devolución';
  const isPurchaseOrder = expenseDetail?.purchase_table_name === 'pedido-en-compra';
  const navigate = useNavigate();
  let { id } = useParams();

  const handleUpdatePay = () => {
    setBotonPresionado('pago');
    showModalEventual();
  };

  const handleUpdateCompany = () => {
    setBotonPresionado('empresa');
    showModalEventual();
  };

  const showModalAmount = () => {
    setIsModalOpenAmount(!isModalOpenAmount);
  };

  const showModalExcess = () => {
    setIsModalOpenExcess(!isModalOpenExcess);
  };

  const showModalEventual = () => {
    setIsModalOpenEventual(!isModalOpenEventual);
  };

  const handleEventualWeekend = () => {
    setModaldEventWeekend(!modaldEventWeekend);
  };

  let payments = [
    { value: 1, label: 'EFECTIVO', key: 1 },
    // { value: 2, label: 'AME EXPRESS 2', key: 2 },
    // { value: 3, label: 'TRANSFERENCIA', key: 3 },
    { value: 4, label: 'SIN MÉTODO DE PAGO', key: 4 }
  ];

  useEffect(() => {
    clienteAxios
      .get(`/caja-chica/company`)
      .then((response) => {
        let data = response.data.companies;
        let filteredData = data.filter((item) => item.status === '1');
        setCompanyEventual(
          filteredData.map((item) => ({
            value: item.id,
            label: item.name
          }))
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const changePayment = (val) => {
    setPayment(val);
  };

  useEffect(() => {
    clienteAxios
      .get(`/caja-chica/solicitudes-de-compra/por-departamento/ver/${id}`)
      .then((response) => {
        let data = response.data.data[0];
        setLoading(false);
        setExpenseDetail(data);
      })
      .catch((error) => {
        console.error(error);
        Swal.fire({
          icon: 'error',
          title: '¡Error!',
          text: 'No se pudo cargar el detalle de la solicitud',
          confirmButtonText: 'ACEPTAR'
        }).then(() => {
          navigate('/solicitudes-gasto');
          window.location.reload();
        });
      });
  }, [id, loading]);

  const openFile = () => {
    if (expenseDetail?.file !== null) {
      window.open(photosEndpoint + expenseDetail?.file);
    }
  };

  const openFileExcess = () => {
    if (expenseDetail?.returnmoney?.file_exchange_returns !== null) {
      window.open(photosEndpoint + expenseDetail?.returnmoney?.file_exchange_returns);
    }
  };

  const openFileMissing = () => {
    if (expenseDetail?.moremoneyeventual?.file !== null) {
      window.open(photosEndpoint + expenseDetail?.moremoneyeventual?.file);
    }
  };

  const openFileSurplus = () => {
    if (expenseDetail?.lessmoneyeventual?.file !== null) {
      window.open(photosEndpoint + expenseDetail?.lessmoneyeventual?.file);
    }
  };

  const handlePostAuthRequest = () => {
    setIsButtonDisabled(true);
    clienteAxios
      .post(`caja-chica/aprobar-solicitud/por-department`, { id: id })
      // API PARA APROBAR POR MANAGER
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: '¡Autorización de solicitud exitosa!',
          confirmButtonText: 'LISTO'
        }).then(() => {
          navigate('/solicitudes-gasto');
        });
      })
      .catch((error) => {
        setIsButtonDisabled(false);
        console.error(error);
      });
  };

  const handlePostCancelRequest = () => {
    clienteAxios
      .post(`/caja-chica/realizar-cancelacion`, { id: id })
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: '¡Solicitud cancelada exitosamente!',
          confirmButtonText: 'LISTO'
        }).then(() => {
          navigate('/solicitudes-gasto');
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // const handleRejectedReturn = () => {
  //   setIsButtonDisabled(true);
  //   clienteAxios
  //     .post(`/caja-chica/realizar-devolucion/cancelation`, { id: id })
  //     .then(() => {
  //       Swal.fire({
  //         icon: 'success',
  //         title: '¡La devolución se confirmo como rechazada!',
  //         confirmButtonText: 'LISTO'
  //       }).then(() => {
  //         navigate('/solicitudes-gasto');
  //       });
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };

  // const handleConfirmedReturn = () => {
  //   setIsButtonDisabled(true);
  //   clienteAxios
  //     .post(`/caja-chica/realizar-devolucion/confirmada`, { id: id })
  //     .then(() => {
  //       Swal.fire({
  //         icon: 'success',
  //         title: '¡Se ha confirmada la devolución!',
  //         confirmButtonText: 'LISTO'
  //       }).then(() => {
  //         navigate('/solicitudes-gasto');
  //       });
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };

  const handlePostConfirmReceived = () => {
    setIsButtonDisabled(true);
    clienteAxios
      .post(`/caja-chica/confirmar-recibido`, { id: id }) // API PARA CONFIRMAR RECIBIDO
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: '¡Confirmación exitosa!',
          confirmButtonText: 'LISTO'
        }).then(() => {
          setIsButtonDisabled(false);
          setLoading(!loading);
        });
      })
      .catch((error) => {
        setIsButtonDisabled(false);
        console.error(error);
      });
  };

  const handlePostConfirmDelivery = () => {
    setIsButtonDisabled(true);
    clienteAxios
      .post(`/caja-chica/confirmar-entrega`, { id: id }) //API PARA CONFIRMAR COMPRA
      .then(() => {
        expenseDetail?.purchase_table_name === 'pedido-entregado';
        expenseDetail?.type_statu === 'entregado';
        Swal.fire({
          icon: 'success',
          title: '¡Hecho!',
          showCancelButton: false,
          confirmButtonText: 'LISTO'
        }).then(() => {
          setIsButtonDisabled(false);
          navigate('/solicitudes-gasto');
        });
      })
      .catch((error) => {
        setIsButtonDisabled(false);
        console.error(error);
      });
  };

  const handlePostRejectedRequest = () => {
    setIsButtonDisabled(true);
    clienteAxios
      .post(`/caja-chica/rechazar-solicitud`, { id: id })
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: '¡Solicitud denegada exitosamente!',
          confirmButtonText: 'LISTO'
        }).then(() => {
          navigate('/solicitudes-gasto');
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handlePostAutEnv = () => {
    setIsButtonDisabled(true);
    clienteAxios
      .post(`/caja-chica/administrador/solicitudes-de-compra/aprobar`, { id: id }) // API PARA APROBAR POR ADMINISTRADOR
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: '¡Aprobación de solicitud exitosa!',
          confirmButtonText: 'LISTO'
        }).then(() => {
          navigate('/solicitudes-gasto');
        });
      })
      .catch((error) => {
        setIsButtonDisabled(false);
        console.error(error);
      });
  };

  const handlePostChangePayment = () => {
    clienteAxios
      .post(`/caja-chica/actualizar-pago`, { id: id, payment_method_id: payment })
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: '¡Método de pago actualizado correctamente!',
          confirmButtonText: 'LISTO'
        }).then(() => {
          setLoading(!loading);
        });
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          icon: 'error',
          title: error.response.data.message,
          confirmButtonText: 'ACEPTAR'
        });
      });
  };

  const handleReturnSurplus = () => {
    Swal.fire({
      title: `${currencyFormatter(quantitySurplus)}`,
      text: '¿Estás seguro de regresar este monto?',
      icon: 'question',
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        const data = new FormData();
        data.append('purchase_id', id);
        data.append('total_return', quantitySurplus);
        data.append('description', descriptionSurplus);
        if (!isFileRemoved && archive !== null) {
          data.append('file_exchange_returns', archive);
        }
        clienteAxios
          .post(`/caja-chica/return/excess/money`, data)
          .then(() => {
            showModalExcess();
            Swal.fire({
              icon: 'success',
              title: 'Excedente ingresado correctamente!',
              text: 'Por favor, comunícate con el personal de caja chica para devolver el excedente.',
              showCancelButton: false,
              confirmButtonText: 'LISTO'
            }).then(() => {
              setLoading(!loading);
            });
          })
          .catch((error) => {
            Swal.fire({
              icon: 'error',
              title: error.response.data.message,
              confirmButtonText: 'LISTO'
            });
          });
      }
    });
  };

  const handleUpdatePayment = () => {
    Swal.fire({
      title: `${currencyFormatter(amountUpdate)}`,
      text: '¿Estás seguro de actualizar este monto?',
      icon: 'question',
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    })
      .then((result) => {
        if (result.isConfirmed) {
          clienteAxios
            .post(`/caja-chica/actualizar-pago-monto`, {
              id_purchase: id,
              total_update: amountUpdate
            })
            .then(() => {
              showModalAmount();
              Swal.fire({
                icon: 'success',
                title: 'Presupuesto ingresado correctamente!',
                showCancelButton: false,
                confirmButtonText: 'LISTO'
              }).then(() => {
                setLoading(!loading);
              });
            })
            .catch((error) => {
              Swal.fire({
                icon: 'error',
                title: error.response.data.message,
                confirmButtonText: 'ACEPTAR'
              });
            });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleSurplusReceived = () => {
    Swal.fire({
      title: '¿Estás seguro de confirmar el excedente?',
      text: 'Una vez confirmado, no se podrá modificar el estado.',
      icon: 'question',
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        clienteAxios
          .post(`/caja-chica/return/excess/money/confirmation`, { purchase_id: id })
          .then(() => {
            Swal.fire({
              icon: 'success',
              title: '¡Excedente confirmado correctamente!',
              showCancelButton: false,
              confirmButtonText: 'LISTO'
            }).then(() => {
              setLoading(!loading);
            });
          })
          .catch((error) => {
            console.error(error);
            Swal.fire({
              icon: 'error',
              title: '¡Error!',
              text: 'No se pudo confirmar el excedente'
            });
          });
      }
    });
  };

  const handleMissingDelivery = () => {
    Swal.fire({
      title: '¿Estás seguro de confirmar la entrega?',
      text: 'Una vez confirmado, no se podrá modificar el estado.',
      icon: 'question',
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        clienteAxios
          .post(`/confirmation/returnormore/money/eventuales`, { purchase_id: id })
          .then(() => {
            Swal.fire({
              icon: 'success',
              title: '¡Entrega confirmada correctamente!',
              showCancelButton: false,
              confirmButtonText: 'LISTO'
            }).then(() => {
              setLoading(!loading);
            });
          })
          .catch((error) => {
            console.error(error);
            Swal.fire({
              icon: 'error',
              title: '¡Error!',
              text: 'No se pudo confirmar la entrega!'
            });
          });
      }
    });
  };

  const handlePayChange = (value, key) => {
    const updatedData = expenseDetail.event.map((event, index) => {
      if (index === key) {
        return { ...event, editablePay: value };
      }
      return event;
    });
    setExpenseDetail((prevExpenseDetail) => ({
      ...prevExpenseDetail,
      event: updatedData
    }));
  };

  const handleCompanyChange = (value, key) => {
    const updatedData = expenseDetail.event.map((event, index) => {
      if (index === key) {
        return { ...event, editableCompany: value };
      }
      return event;
    });
    setExpenseDetail((prevExpenseDetail) => ({
      ...prevExpenseDetail,
      event: updatedData
    }));
  };

  const finishFromEventWeekend = (value) => {
    handleSenEventWeekend(value);
  };

  const handleSenEventWeekend = (eventuales) => {
    if (parseFloat(expenseDetail?.total) !== totalEventuals && archive === null) {
      Swal.fire({
        icon: 'error',
        title: '¡Error!',
        text: 'No has cargado un comprobante',
        confirmButtonText: 'INTENTAR DE NUEVO'
      });
      return;
    } //API FALLA
    //regresar presupuesto tambien
    let formData = new FormData();
    formData.append('id_purchase', id);
    formData.append('description', eventuales.description);
    formData.append('file', archive);
    eventuales.eventuales.forEach((event, index) => {
      formData.append(`eventuales[${index}][name]`, event.name);
      formData.append(`eventuales[${index}][pay]`, event.pay);
      formData.append(`eventuales[${index}][company]`, event.company);
    });
    setIsButtonDisabled(true);
    clienteAxios
      .post(`caja-chica/add/eventual/finde`, formData)
      .then(() => {
        setModaldEventWeekend(false);
        Swal.fire({
          icon: 'success',
          title: 'Eventuales registrados correctamente!',
          confirmButtonText: 'LISTO'
        }).then(() => {
          setLoading(!loading);
          setIsButtonDisabled(false);
        });
      })
      .catch((error) => {
        console.error(error);
        Swal.fire({
          icon: 'error',
          title: 'Error al registrar eventuales',
          confirmButtonText: 'INTENTAR DE NUEVO'
        });
      });
  };

  const handleEditAny = () => {
    const id_eventual = [];
    const new_pay = [];
    const id_company = [];
    expenseDetail.event.forEach((event) => {
      id_eventual.push(event.id);
      new_pay.push(
        event.editablePay !== undefined && event.editablePay !== null
          ? event.editablePay
          : event.pay
      );
      id_company.push(event.editableCompany || event.company);
    });
    clienteAxios
      .post(`caja-chica/editar/eventuales`, { purchase_id: id, id_eventual, new_pay, id_company })
      .then(() => {
        setIsModalOpenEventual(false);
        Swal.fire({
          icon: 'success',
          title: 'Eventual (es) actualizado (s) correctamente!',
          confirmButtonText: 'LISTO'
        }).then(() => {
          setLoading(!loading);
        });
      })
      .catch((error) => {
        console.error(error);
        Swal.fire({
          icon: 'error',
          title: error.response.data.message,
          confirmButtonText: 'INTENTAR DE NUEVO'
        });
      });
  };

  const columns = [
    {
      title: 'NOMBRE',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'PAGO',
      dataIndex: 'pay',
      key: 'pay',
      render: (text) => currencyFormatter(text)
    },
    {
      title: 'EMPRESA',
      dataIndex: 'company_name',
      key: 'company_name'
    }
  ];

  const eventualColumns = [
    {
      title: 'NOMBRE',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'PAGO',
      dataIndex: 'editablePay',
      key: 'editablePay',
      render: (text, record) => {
        if (botonPresionado === 'pago') {
          return (
            <InputNumber
              min={0}
              defaultValue={text}
              onChange={(value) => handlePayChange(value, record.key)}
            />
          );
        } else {
          return currencyFormatter(text);
        }
      }
    },
    {
      title: 'EMPRESA',
      dataIndex: 'editableCompany',
      key: 'editableCompany',
      render: (text, record) => {
        if (botonPresionado === 'empresa') {
          return (
            <Select
              defaultValue={text}
              options={companyEventual}
              className="width-100"
              showSearch
              filterOption={(value, option) =>
                option.label.toLowerCase().indexOf(value.toLowerCase()) >= 0
              }
              onChange={(value) => handleCompanyChange(value, record.key)}
            />
          );
        } else {
          return text;
        }
      }
    }
  ];

  return (
    <Spin tip="Cargando detalle de solicitud" spinning={loading}>
      <>
        <FormDetalleGasto
          expenseDetail={expenseDetail}
          handleUpdateCompany={handleUpdateCompany}
          handleEventualWeekend={handleEventualWeekend}
          handleUpdatePay={handleUpdatePay}
          showModalAmount={showModalAmount}
          showModalExcess={showModalExcess}
          openFileExcess={openFileExcess}
          openFileSurplus={openFileSurplus}
          openFileMissing={openFileMissing}
          payments={payments}
          payment={payment}
          changePayment={changePayment}
          handlePostChangePayment={handlePostChangePayment}
          isNormal={isNormal}
          isShopping={isShopping}
          isAdquision={isAdquision}
          isCancel={isCancel}
          isPurchaseOrder={isPurchaseOrder}
          isReceived={isReceived}
          isDevolution={isDevolution}
          isDevolutionReturn={isDevolutionReturn}
          decodedToken={decodedToken}
          isUser={isUser}
          columns={columns}
          navigate={navigate}
          isSuperAdmin={isSuperAdmin}
        />

        {expenseDetail?.file && (
          <Boton type="secondary" icon={<EyeOutlined />} onClick={openFile}>
            Visualizar Archivo o Imagen
          </Boton>
        )}

        <div className="flex justify-content-end mt-4">
          {decodedToken?.role?.name === 'manager' &&
          expenseDetail?.approved_status === 'pendiente' ? (
            <>
              <Form onFinish={handlePostRejectedRequest}>
                <Form.Item>
                  <Boton
                    type="secondary"
                    htmlType="submit"
                    danger
                    className="ml-1"
                    disabled={isButtonDisabled}>
                    Denegar
                  </Boton>
                </Form.Item>
              </Form>
              <Form onFinish={handlePostAuthRequest}>
                <Form.Item>
                  <Boton
                    type="primary"
                    htmlType="submit"
                    className="ml-4"
                    disabled={isButtonDisabled}>
                    Autorizar
                  </Boton>
                </Form.Item>
              </Form>
            </>
          ) : (isAdmin || isSuperAdmin) &&
            expenseDetail?.purchase_status === 'En proceso' &&
            (expenseDetail?.approved_status === 'pendiente' ||
              expenseDetail?.approved_status === 'en aprobación por administrador') ? (
            <>
              <Form onFinish={handlePostRejectedRequest}>
                <Form.Item>
                  <Boton
                    type="secondary"
                    htmlType="submit"
                    danger
                    className="ml-1"
                    disabled={isButtonDisabled}>
                    Denegar
                  </Boton>
                </Form.Item>
              </Form>
              <Form onFinish={handlePostAutEnv}>
                <Form.Item>
                  <Boton
                    type="primary"
                    htmlType="submit"
                    className="ml-4"
                    disabled={isButtonDisabled}>
                    Autorizar
                  </Boton>
                </Form.Item>
              </Form>
            </>
          ) : (isShopping || isAdquision || isSuperAdmin) &&
            isCancel &&
            expenseDetail?.purchase_table_name === 'pedido-en-compra' ? (
            <>
              <Form onFinish={handlePostCancelRequest}>
                <Form.Item>
                  <Boton
                    type="secondary"
                    danger
                    htmlType="submit"
                    className="ml-4"
                    disabled={
                      isButtonDisabled || expenseDetail?.payment_method !== 'SIN MÉTODO DE PAGO'
                    }>
                    Cancelar entrega
                  </Boton>
                </Form.Item>
              </Form>
              <Form onFinish={handlePostConfirmDelivery}>
                <Form.Item>
                  <Boton
                    type="primary"
                    htmlType="submit"
                    className="ml-4"
                    disabled={
                      isButtonDisabled || expenseDetail?.payment_method === 'SIN MÉTODO DE PAGO'
                    }>
                    Confirmar entrega
                  </Boton>
                </Form.Item>
              </Form>
            </>
          ) : expenseDetail?.purchase_table_name === 'pedido-entregado' && isUser ? (
            <>
              {/* <Form onFinish={handlePostReturnRequest}>
              <Form.Item>
                <Boton
                  type="secondary"
                  danger
                  htmlType="submit"
                  className="ml-4"
                  disabled={isButtonDisabled}>
                  Realizar devolución
                </Boton>
              </Form.Item>
            </Form> */}
              <Form onFinish={handlePostConfirmReceived}>
                <Form.Item>
                  <Boton
                    type="primary"
                    htmlType="submit"
                    className="ml-4"
                    disabled={isButtonDisabled}>
                    Confirmar de recibido
                  </Boton>
                </Form.Item>
              </Form>
            </>
          ) : // ) : isShopping &&
          //   expenseDetail?.purchase_table_name === 'pedido-entregado' &&
          //   expenseDetail?.approved_status === 'aprobada' ? (
          //   <>
          //     <Form onFinish={handlePostReturnRequest}>
          //       <Form.Item>
          //         <Boton
          //           type="secondary"
          //           danger
          //           htmlType="submit"
          //           className="ml-4"
          //           disabled={isButtonDisabled}>
          //           Realizar devolución
          //         </Boton>
          //       </Form.Item>
          //     </Form>
          //   </>
          (isShopping || isAdquision) &&
            expenseDetail?.returnmoney?.status === 'Sin confirmar' &&
            !Array.isArray(expenseDetail?.returnmoney) &&
            isReceived ? (
            <>
              <Form onFinish={handleSurplusReceived}>
                <Form.Item>
                  <Boton
                    type="secondary"
                    htmlType="submit"
                    className="ml-4"
                    disabled={isButtonDisabled}>
                    Confirmar devolución excedente
                  </Boton>
                </Form.Item>
              </Form>
            </>
          ) : (isShopping || isAdquision) &&
            //solo si el moremoneyeventual su array tiene un daro, si es cero no entre a las condiciones
            expenseDetail?.moremoneyeventual &&
            expenseDetail?.moremoneyeventual?.status === 'Sin confirmar' &&
            isReceived ? (
            <>
              <Form onFinish={handleMissingDelivery}>
                <Form.Item>
                  <Boton
                    type="secondary"
                    htmlType="submit"
                    className="ml-4"
                    disabled={isButtonDisabled}>
                    Confirmar entrega de faltante
                  </Boton>
                </Form.Item>
              </Form>
            </>
          ) : (isShopping || isAdquision) &&
            expenseDetail?.lessmoneyeventual &&
            expenseDetail?.lessmoneyeventual?.status === 'Sin confirmar' &&
            isReceived ? (
            <>
              <Form onFinish={handleMissingDelivery}>
                <Form.Item>
                  <Boton
                    type="secondary"
                    htmlType="submit"
                    className="ml-4"
                    disabled={isButtonDisabled}>
                    Confirmar entrega de sobrante
                  </Boton>
                </Form.Item>
              </Form>
            </>
          ) : expenseDetail?.approved_status === 'devolución' &&
            expenseDetail?.type_status === 'en proceso' &&
            (isShopping || isAdquision || isUser) &&
            expenseDetail?.purchase_table_name === 'pedido-devolución' ? (
            <>
              {/* <Form onFinish={handleRejectedReturn}>
                <Form.Item>
                  <Boton
                    type="secondary"
                    danger
                    htmlType="submit"
                    className="ml-4"
                    disabled={isButtonDisabled}>
                    Devolución rechazada
                  </Boton>
                </Form.Item>
              </Form>
              <Form onFinish={handleConfirmedReturn}>
                <Form.Item>
                  <Boton
                    type="primary"
                    htmlType="submit"
                    className="ml-4"
                    disabled={isButtonDisabled}>
                    Confirmar devolución
                  </Boton>
                </Form.Item>
              </Form> */}
            </>
          ) : null}
        </div>

        <ModalActualizarMonto
          isModalOpenAmount={isModalOpenAmount}
          showModalAmount={showModalAmount}
          handleUpdatePayment={handleUpdatePayment}
          setAmountUpdate={setAmountUpdate}
        />

        <ModalRegresarExcedente
          isModalOpenExcess={isModalOpenExcess}
          showModalExcess={showModalExcess}
          handleReturnSurplus={handleReturnSurplus}
          setQuantitySurplus={setQuantitySurplus}
          setDescriptionSurplus={setDescriptionSurplus}
          setArchive={setArchive}
          setIsFileRemoved={setIsFileRemoved}
        />

        <ModalDatosEvetuales
          isModalOpenEventual={isModalOpenEventual}
          showModalEventual={showModalEventual}
          handleEditAny={handleEditAny}
          expenseDetail={expenseDetail}
          eventualColumns={eventualColumns}
        />

        <ModalRegistrarEventuales
          modaldEventWeekend={modaldEventWeekend}
          handleEventualWeekend={handleEventualWeekend}
          finishFromEventWeekend={finishFromEventWeekend}
          expenseDetail={expenseDetail}
          totalEventuals={totalEventuals}
          eventualsPay={eventualsPay}
          setEventualsPay={setEventualsPay}
          setTotalEventuals={setTotalEventuals}
          companyEventual={companyEventual}
          setArchive={setArchive}
          setIsFileRemoved={setIsFileRemoved}
          isButtonDisabled={isButtonDisabled}
        />
      </>
    </Spin>
  );
};

export default DetalleGasto;
