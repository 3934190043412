import { Input, Space, Table, Tag, Form, Select } from 'antd';
import React, { useState, useRef, useEffect } from 'react';
import { SearchOutlined, CheckCircleOutlined, StopOutlined, EditOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import localeObjectTable from '../../Utils/localeObjectTable';
import PropTypes from 'prop-types';
import { DatePicker } from 'antd';
import moment from 'moment';
import clienteAxios from '../../Utils/clienteAxios';
import Swal from 'sweetalert2';
import es from 'antd/lib/date-picker/locale/es_ES';
import Boton from '../Atoms/Boton';
import Modal from '../Atoms/Modal';

const TablaConceptos = ({ data, setLoading, loading }) => {
  const { RangePicker } = DatePicker;
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedUserData, setSelectedUserData] = useState(null);
  const [editedConcept, setEditedConcept] = useState('');
  const [editedCategory, setEditedCategory] = useState('');
  const [editedBuy, setEditedBuy] = useState('');
  const [editedName, setEditedName] = useState('');
  const [editedSpending, setEditedSpending] = useState('');
  const [costCenters, setCostCenters] = useState([]);

  let OptionsCategory = [
    { value: 'VARIABLE', label: 'VARIABLE', key: 1 },
    { value: 'FIJO', label: 'FIJO', key: 2 }
  ];

  let OptionSpending = [
    { value: 'GASTO', label: 'GASTO', key: 1 },
    { value: 'COSTO', label: 'COSTO', key: 2 },
    { value: 'INVERSION', label: 'INVERSION', key: 3 }
  ];

  let OptionBuy = [
    { value: 'SERVICIO', label: 'SERVICIO', key: 1 },
    { value: 'PRODUCTO', label: 'PRODUCTO', key: 2 }
  ];

  const showModal = (userData) => {
    setSelectedUserData(userData);
    setEditedConcept(userData?.concept || '');
    setEditedCategory(userData?.outgo_type || '');
    setEditedBuy(userData?.product_type || '');
    setEditedName(userData?.center_id.id || '');
    setEditedSpending(userData?.expense_type || '');
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: '0 8px 8px 8px'
        }}
        onKeyDown={(e) => e.stopPropagation()}>
        <Boton
          type="link"
          size="small"
          onClick={() => {
            close();
          }}
          className="button-close-search-filter-table">
          X
        </Boton>
        <Input
          ref={searchInput}
          placeholder={`Buscar`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block'
          }}
        />
        <Space>
          <Boton
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small">
            Buscar
          </Boton>
          <Boton onClick={() => clearFilters && handleReset(clearFilters)} danger size="small">
            Limpiar
          </Boton>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      )
  });

  // peticion post para desactivar la empresa por el id de la empresa
  const handleDesactivarConcepto = async (id) => {
    Swal.fire({
      title: 'Desactivar concepto',
      text: '¿Estás seguro de desactivar el concepto?',
      icon: 'warning',
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonText: '¡Sí, desactivar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        clienteAxios
          .post(`/caja-chica/gastos/borrar`, { id_spents: id })
          .then(() => {
            Swal.fire({
              icon: 'success',
              title: '¡Desactivado!',
              text: 'El concepto ha sido desactivado.',
              confirmButtonText: 'Aceptar'
            }).then(() => {
              setLoading(true);
            });
          })
          .catch(() => {
            Swal.fire({
              icon: 'error',
              title: '¡Error!',
              confirmButtonText: 'INTENTAR DE NUEVO',
              text: 'No se pudo desactivar el concepto.'
            });
          });
      }
    });
  };

  // peticion post para desactivar la empresa por el id de la empresa
  const handleActivarConcepto = async (id) => {
    Swal.fire({
      title: 'Activar concepto',
      text: '¿Estás seguro de activar el concepto?',
      icon: 'warning',
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonText: '¡Sí, activar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        clienteAxios
          .post(`/caja-chica/gastos/activate`, { id_spents: id })
          .then(() => {
            Swal.fire({
              icon: 'success',
              title: '¡Activado!',
              text: 'El concepto ha sido activado.',
              confirmButtonText: 'Aceptar'
            }).then(() => {
              setLoading(true);
            });
          })
          .catch(() => {
            Swal.fire({
              icon: 'error',
              title: '¡Error!',
              confirmButtonText: 'INTENTAR DE NUEVO',
              text: 'No se pudo activar el concepto.'
            });
          });
      }
    });
  };

  useEffect(() => {
    clienteAxios
      .get(`/caja-chica/centros-de-costos/ver`)
      .then((response) => {
        let data = response.data.centers;
        const sortedCenter = data
          .map((item) => ({
            value: item.id,
            label: item.name,
            key: item.id
          }))
          .sort((a, b) => a.label.localeCompare(b.label));
        setCostCenters(sortedCenter);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const tablaReporte = [
    {
      title: 'ID CONCEPTO',
      dataIndex: 'id',
      key: 'id',
      ...getColumnSearchProps('id'),
      sorter: (a, b) => a.id - b.id,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'CONCEPTO',
      dataIndex: 'concept',
      key: 'concept',
      ...getColumnSearchProps('concept'),
      sorter: (a, b) => a.concept.localeCompare(b.concept),
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'CENTRO DE COSTO',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name'),
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'CATEGORÍA',
      dataIndex: 'outgo_type',
      key: 'outgo_type',
      filters: [
        {
          text: 'FIJO',
          value: 'FIJO'
        },
        {
          text: 'VARIABLE',
          value: 'VARIABLE'
        }
      ],
      onFilter: (value, record) => record.outgo_type === value,
      sorter: (a, b) => a.outgo_type.localeCompare(b.outgo_type),
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'TIPO DE ADQUISICIÓN',
      dataIndex: 'product_type',
      key: 'product_type',
      filters: [
        {
          text: 'SERVICIO',
          value: 'SERVICIO'
        },
        {
          text: 'PRODUCTO',
          value: 'PRODUCTO'
        }
      ],
      onFilter: (value, record) => record.product_type === value,
      sorter: (a, b) => a.product_type.localeCompare(b.product_type),
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'TIPO DE GASTO',
      dataIndex: 'expense_type',
      key: 'expense_type',
      filters: [
        {
          text: 'GASTO',
          value: 'GASTO'
        },
        {
          text: 'COSTO',
          value: 'COSTO'
        },
        {
          text: 'INVERSIÓN',
          value: 'INVERSIÓN'
        }
      ],
      onFilter: (value, record) => record.expense_type === value,
      sorter: (a, b) => a.expense_type.localeCompare(b.expense_type),
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'ESTADO',
      dataIndex: 'status',
      key: 'status',
      filters: [
        {
          text: 'Activado',
          value: '1'
        },
        {
          text: 'Desactivado',
          value: '0'
        }
      ],
      onFilter: (value, record) => record.status === value,
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortDirections: ['ascend', 'descend'],
      render: (text) => {
        switch (text) {
          case '0':
            return <Tag color="error">Desactivado</Tag>;
          case '1':
            return <Tag color="geekblue">Activado</Tag>;
          default:
            return <Tag color="gold-inverse">{text}</Tag>;
        }
      }
    },
    {
      title: 'ULTIMA ACTUALIZACIÓN',
      dataIndex: 'updated_at',
      key: 'updated_at',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
        <div
          style={{
            padding: '0 8px 8px 8px'
          }}
          onKeyDown={(e) => e.stopPropagation()}>
          <Boton
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
            className="button-close-search-filter-table">
            X
          </Boton>
          <RangePicker
            locale={es}
            placeholder={['Fecha inicial', 'Fecha final']}
            value={selectedKeys[0]}
            onChange={(dates) => {
              setSelectedKeys([dates]);
            }}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Boton
              type="primary"
              onClick={() => {
                confirm();
              }}
              icon={<SearchOutlined />}
              size="small">
              Buscar
            </Boton>

            <Boton onClick={clearFilters} size="small" danger>
              Limpiar
            </Boton>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{
            color: filtered ? '#1890ff' : undefined
          }}
        />
      ),
      onFilter: (value, record) => {
        if (!value) return true;
        const start = value[0].startOf('day').toDate(); // Convertir a objeto Date
        const end = value[1].endOf('day').toDate(); // Convertir a objeto Date
        const created = moment(record.updated_at, 'DD-MM-YYYY').toDate(); // Convertir a objeto Date
        return created >= start && created <= end;
      },
      sorter: (a, b) => moment(a.updated_at, 'DD-MM-YYYY') - moment(b.updated_at, 'DD-MM-YYYY'),
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'ACCIONES',
      dataIndex: 'acciones',
      key: 'acciones'
    }
  ];

  const newDataSOL = data?.map((item) => {
    return {
      key: item.id,
      id: item.id,
      concept: item?.concept,
      outgo_type: item?.outgo_type,
      product_type: item?.product_type,
      expense_type: item?.expense_type,
      name: item?.center_id.name,
      status: item?.status,
      updated_at: item?.updated_at,
      acciones: (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Boton
            className="btn-tertiary-outlined ml-1 mr-1 mt-1 mb-1"
            icon={<EditOutlined />}
            onClick={() => showModal(item)}
          />
          <Boton
            className="btn-secondary ml-1 mr-1 mt-1 mb-1"
            icon={<CheckCircleOutlined />}
            disabled={item.status === '1'}
            onClick={() => handleActivarConcepto(item.id)}
          />
          <Boton
            className="btn-danger ml-1 mr-1 mt-1 mb-1"
            danger
            icon={<StopOutlined />}
            disabled={item.status === '0'}
            onClick={() => handleDesactivarConcepto(item.id)}
          />
        </div>
      )
    };
  });

  const handleOk = () => {
    setIsSubmitting(true);
    clienteAxios
      .post(`/caja-chica/gastos/editar`, {
        id: selectedUserData.id,
        concept: editedConcept,
        center_id: editedName,
        outgo_type: editedCategory,
        expense_type: editedSpending,
        product_type: editedBuy
      })
      .then(() => {
        setLoading(true);
        setIsSubmitting(false);
        handleCancel();
        Swal.fire({
          icon: 'success',
          title: 'Concepto actualizado correctamente!',
          confirmButtonText: 'LISTO'
        }).then(() => {
          setLoading(false);
        });
      })
      .catch((error) => {
        console.error(error);
        setIsSubmitting(false);
        Swal.fire({
          icon: 'error',
          title: 'Error al actualizar el concepto!',
          confirmButtonText: 'INTENTAR DE NUEVO'
        });
      });
  };

  return (
    <>
      <Table
        loading={loading}
        columns={tablaReporte}
        dataSource={newDataSOL}
        pagination={{
          position: ['bottomCenter'],
          defaultPageSize: 10,
          pageSizeOptions: [20, 40, 60, 80, 100],
          total: data.total,
          showTotal: (total, range) =>
            `Mostrando empresas del ${range[0]} al ${range[1]} de un total de ${total}`
        }}
        className="mt-4 mb-4"
        locale={localeObjectTable}
        scroll={{
          x: true
        }}
      />
      <Modal
        title="EDITAR CONCEPTO"
        centered
        open={isModalOpen}
        onCancel={handleCancel}
        onOk={handleOk}
        confirmLoading={isSubmitting}
        okText="Actualizar">
        <Form
          className="mt-4"
          labelCol={{
            span: 10
          }}
          labelAlign="left"
          wrapperCol={{
            span: 14
          }}>
          <Form.Item label="Nombre del Concepto">
            <Input
              placeholder="Concepto"
              value={editedConcept}
              onChange={(e) => setEditedConcept(e.target.value)}
              onInput={(e) => {
                e.target.value = e.target.value.toUpperCase();
              }}
            />
          </Form.Item>
          <Form.Item label="Centro de Costo">
            <Select
              placeholder="Seleccione una opción"
              value={editedName}
              onChange={(value) => setEditedName(value)}>
              {costCenters.map((option) => (
                <Select.Option key={option.key} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Tipo de adquisición">
            <Select
              placeholder="Seleccione una opción"
              value={editedBuy}
              onChange={(value) => setEditedBuy(value)}>
              {OptionBuy.map((option) => (
                <Select.Option key={option.key} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Tipo de gasto">
            <Select
              placeholder="Seleccione una opción"
              value={editedSpending}
              onChange={(value) => setEditedSpending(value)}>
              {OptionSpending.map((option) => (
                <Select.Option key={option.key} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Categoría">
            <Select
              placeholder="Seleccione una opción"
              value={editedCategory}
              onChange={(value) => setEditedCategory(value)}>
              {OptionsCategory.map((option) => (
                <Select.Option key={option.key} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

TablaConceptos.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  setLoading: PropTypes.func
};

export default TablaConceptos;
