import React, { useEffect, useRef } from 'react';
import { Table, InputNumber, Form } from 'antd';
import '../../Assets/Styles/Organisms/TablaInspeccionProductos.css';
import PropTypes, { string } from 'prop-types';

let textOfAspects = [
  'Color o pantone incorrecto',
  'Daño en el logo',
  'Logo incorrecto',
  'Piezas incompletas',
  'Mercancía no cortada',
  'Dimensiones diferentes al virtual o especialización',
  'Producto con daños, golpes o retrasos',
  'Producto no realiza su función de manera correcta',
  'Código de producto incorrecto'
];

const TablaCaracteristicasInspeccionCalidad = ({
  type,
  totalRejectedProducts,
  setTotalRejectedProducts,
  quantities,
  setQuantities,
  factures
}) => {
  useEffect(() => {
    if (type === 'get' && factures !== undefined) {
      setTotalRejectedProducts(factures?.total);
    }
  }, [factures]);

  const handleChangeInputQuantity = (value, index) => {
    let tempObj = [...quantities];
    tempObj[index] = value;
    setQuantities(tempObj);
  };

  useEffect(() => {
    if (type === 'post') {
      setTotalRejectedProducts(quantities.reduce((a, b) => a + b, 0));
    }
  }, [quantities]);

  const containerRef = useRef(null);

  useEffect(() => {
    const handleWheel = (e) => {
      if (document.activeElement === containerRef.current.querySelector('input')) {
        e.preventDefault();
      }
    };

    const containerElement = containerRef.current;
    if (containerElement) {
      // Agregar el listener de evento de manera no pasiva
      containerElement.addEventListener('wheel', handleWheel, { passive: false });

      // Limpiar el listener cuando el componente se desmonte
      return () => {
        containerElement.removeEventListener('wheel', handleWheel);
      };
    }
  }, []);

  const columns = [
    {
      title: 'CARACTERÍSTICA',
      dataIndex: 'caracteristica',
      key: 'caracteristica',
      align: 'left'
    },
    {
      title: 'CANTIDAD CON DEFECTO',
      dataIndex: 'cantidadNoCumpleOjb',
      key: 'cantidadNoCumpleOjb'
    }
  ];

  let data = textOfAspects?.map((quantity, index) => ({
    key: index,
    caracteristica: textOfAspects[index],
    cantidadNoCumpleOjb: (
      <div>
        <Form.Item name={`inputQuantity_${index}`}>
          {type === 'get' ? (
            index === 0 ? (
              factures?.wrong_pantone_color
            ) : index === 1 ? (
              factures?.damage_logo
            ) : index === 2 ? (
              factures?.incorrect_logo
            ) : index === 3 ? (
              factures?.incomplete_pieces
            ) : index === 4 ? (
              factures?.merchandise_not_cut
            ) : index === 5 ? (
              factures?.different_dimensions
            ) : index === 6 ? (
              factures?.damaged_products
            ) : index === 7 ? (
              factures?.product_does_not_perform_its_function
            ) : index === 8 ? (
              factures?.wrong_product_code
            ) : null
          ) : type === 'post' ? (
            <div ref={containerRef}>
              <InputNumber
                className="centered-input"
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                onChange={(value) => handleChangeInputQuantity(value, index)}
                min={0}
                placeholder="0"
                value={quantities[index]}
              />
            </div>
          ) : null}
        </Form.Item>
      </div>
    )
  }));

  data.push({
    key: 9,
    caracteristica: 'TOTAL',
    cantidadNoCumpleOjb: <span>{totalRejectedProducts}</span>
  });

  return (
    <Table
      className="tabla-inspec-productos"
      scroll={{ x: false }}
      bordered
      columns={columns}
      dataSource={data}
      pagination={false}
    />
  );
};

TablaCaracteristicasInspeccionCalidad.propTypes = {
  type: string,
  totalRejectedProducts: PropTypes.number,
  setTotalRejectedProducts: PropTypes.func,
  defaultQuantities: PropTypes.array,
  quantities: PropTypes.array,
  setQuantities: PropTypes.func,
  factures: PropTypes.object
};

export default TablaCaracteristicasInspeccionCalidad;
