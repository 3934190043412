import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Table, Input, DatePicker, Row, Col, Form, ConfigProvider } from 'antd';
import clienteAxios from '../../Utils/clienteAxios';
import localeObjectTable from '../../Utils/localeObjectTable';
import parseDate from '../../Utils/parseDate';
import Status2 from '../Molecules/Status2';
import es_ES from 'antd/locale/es_ES';

const TablaListaPed = () => {
  const [responseGetPedidos, setResponseGetPedidos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterData, setFilterData] = useState([]);
  const [searchFilters, setSearchFilters] = useState([]);
  const [numPage, setNumPage] = useState(1);
  const [numPerPage, setNumPerPage] = useState(10);
  const [locale] = useState(es_ES);
  const { Search } = Input;

  useEffect(() => {
    getPedidos();
  }, [filterData, numPage, numPerPage]);

  const params = {
    ...(filterData || {})
  };

  const handlePaginationChange = (page, pageSize) => {
    setNumPage(page);
    setNumPerPage(pageSize);
  };

  const getPedidos = (page = numPage, perPage = numPerPage) => {
    setLoading(true);
    clienteAxios
      .get(`/pedidos?page=${page}&per_page=${perPage}&ordenes_proximas=`, {
        params: {
          ...params
        }
      })
      .then((response) => {
        let data = response.data.data.sales;
        setResponseGetPedidos(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  console.log('responseGetPedidos', responseGetPedidos);

  const handleSearch2 = (e) => {
    const newSearchFilter = {
      name: 'idPedidos',
      value: e
    };
    setSearchFilters([...searchFilters, newSearchFilter]);
  };

  const handleDate = (date, dateString) => {
    const newSearchFilter = {
      name: 'fechaCreacion',
      value: dateString
    };
    setSearchFilters([...searchFilters, newSearchFilter]);
  };

  useEffect(() => {
    const dataFilter = Object.values(
      searchFilters.reduce((acc, obj) => {
        acc[obj.name] = obj;
        return acc;
      }, {})
    );

    const newDataFilter = dataFilter.reduce((acc, obj) => {
      acc[obj.name] = obj.value;
      return acc;
    }, {});
    if (searchFilters.length > 0) {
      setFilterData(newDataFilter);
      setNumPage(1);
    }
  }, [searchFilters]);

  const columnsTablaPedidos = [
    {
      title: 'ID PEDIDO',
      dataIndex: 'idPedidos',
      key: 'idPedidos',
      fixed: 'left',
      render: (text) => <Link to={`/pedidos/${text}`}>{text}</Link>
    },

    {
      title: 'FECHA DE CREACIÓN',
      dataIndex: 'fechaCreacion',
      key: 'fechaCreacion',
      width: 150
    },
    {
      title: 'ESTADOS',
      dataIndex: 'estado',
      key: 'estado'
    }
  ];

  const newDataListaPedidos = responseGetPedidos?.data?.map((item) => {
    let accionesButton = {};
    return {
      key: item?.id,
      idPedidos: item?.code_sale,
      fechaCreacion: parseDate(item?.order_date, 'short'),
      details_orders: item?.details_orders,
      estado: (
        <>
          <div style={{ overflowY: 'auto', maxHeight: '170px', minWidth: '250px' }}>
            <Status2 status={item?.last_status === undefined ? [] : item?.last_status} />
          </div>
        </>
      ),
      ...accionesButton
    };
  });

  return (
    <>
      <>
        <Row>
          <Col xs={{ span: 24 }} lg={{ span: 8 }} style={{ marginBottom: '-15px' }}>
            <Form>
              <Form.Item label="Buscar por fecha de creación:">
                <ConfigProvider locale={locale}>
                  <DatePicker onChange={handleDate}></DatePicker>
                </ConfigProvider>
              </Form.Item>
            </Form>
          </Col>

          <Col xs={{ span: 24 }} lg={{ span: 10, offset: 6 }} style={{ marginBottom: '-15px' }}>
            <Form>
              <Form.Item label="Buscar ID del pedido">
                <Search
                  placeholder="Buscar pedido"
                  style={{ marginBottom: 16 }}
                  allowClear
                  onSearch={(value) => handleSearch2(value)}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>

        <Table
          columns={columnsTablaPedidos}
          dataSource={newDataListaPedidos}
          loading={loading}
          pagination={{
            position: ['bottomRight'],
            onChange: handlePaginationChange,
            total: responseGetPedidos.total,
            showTotal: (total, range) =>
              `Mostrando pedidos del ${range[0]} al ${range[1]} de un total de ${total}`
          }}
          locale={localeObjectTable}
          scroll={{
            x: true
          }}
        />
      </>
    </>
  );
};

TablaListaPed.defaultProps = {
  buttonColumnAdd: false,
  disablePostButtons: false
};

TablaListaPed.propTypes = {
  buttonColumnAdd: PropTypes.bool,
  disablePostButtons: PropTypes.bool,
  setmanejarCambio: PropTypes.func,
  manejarCambio: PropTypes.bool
};
export default TablaListaPed;
