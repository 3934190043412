import React, { useEffect, useState } from 'react';
import { Alert, Col, Form, Row, Spin } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Status2 from '../Components/Molecules/Status2';
import { Collapse } from 'antd';
import clienteAxios from '../Utils/clienteAxios';
import TablaDetallesCompra from '../Components/Organisms/TablaDetallesCompra';
import TableTrazavilidadProducto from '../Components/Organisms/TableTrazavilidadProducto';
import TablaRecpcionInventario from '../Components/Organisms/TablaRepcionInventario';
import SeccionHistorialIncidencias from '../Components/Organisms/SeccionHistorialIncidencias';
import parseDate from '../Utils/parseDate';
import Card from '../Components/Organisms/Card';
import ModalCheckList from '../Components/Organisms/ModalCheckList';
import HistoryCheckList from '../Components/Organisms/HistoryCheckList';
import TablaInspeccion from '../Components/Organisms/TablaInspeccion';
import decodeToken from '../Utils/decodeToken';
import TableProductosServicios from '../Components/Organisms/TableProductosServicios';

const DetallePedido = () => {
  const [dataPed, setDataPed] = useState({});
  const [loading, setLoading] = useState(true);
  let { id } = useParams();
  let decodedToken = decodeToken(localStorage.getItem('token'));

  let userDeparment = decodedToken?.user?.display_name_department;
  let userArea = decodedToken?.user?.display_name_area;
  let userRole = decodedToken?.role?.name;

  const navigate = useNavigate();

  useEffect(() => {
    clienteAxios
      .get(`/pedido/${id}`)
      .then((response) => {
        let data = response?.data;
        data.order_date = parseDate(data?.order_date, 'short');
        setDataPed(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [id, loading]);

  const pedidoId = dataPed?.additional_information?.code_sale;

  const ordenesDeCompra = dataPed?.orders?.filter(
    (orden) => orden?.code_order?.substring(0, 2) === 'OC'
  );

  const ordenesDeTrabajo = dataPed?.orders?.filter(
    (orden) => orden?.code_order?.substring(0, 2) === 'OT'
  );

  const margeArray = dataPed?.products_orders?.flatMap((product) =>
    dataPed?.orders
      .filter((order) => order?.code_order === product?.code_order)
      .map((order) => ({
        ...product,
        confirmation: order?.Confirmation,
        idProductsOrders: order?.idProductsOrders
      }))
  );

  const items = [
    userArea === 'compras' ||
    userDeparment === 'ventas' ||
    userArea === 'mesa_de_control' ||
    userArea === 'sgcmc' ||
    decodedToken?.sub === 280 ||
    userDeparment === 'direccion' ||
    userRole === 'Super_Admin'
      ? {
          key: '1',
          label: 'CONFIRMARCIÓN OC / OT',
          children: (
            <>
              <TablaDetallesCompra
                code_sale={pedidoId}
                dataPed={dataPed?.orders}
                products={dataPed?.products_orders}
                setLoading={setLoading}
                loading={loading}
                statusProduct={dataPed?.status}></TablaDetallesCompra>
            </>
          )
        }
      : null,
    userArea === 'compras' ||
    userArea === 'sgcmc' ||
    userRole === 'Super_Admin' ||
    decodedToken?.sub === 280
      ? {
          key: '2',
          label: 'ASIGNACIÓN DE OC / OT',
          children: (
            <>
              <Alert
                type="warning"
                message={
                  <>
                    <strong>Nota: </strong>
                    <span>
                      Solo aparecerán los productos y servicios que se han confirmado, agrupa los
                      servicios según los productos de la orden de compra para poder crear la ruta.
                    </span>
                  </>
                }></Alert>
              <TableProductosServicios
                key={!loading}
                setLoading={setLoading}
                loading={loading}
                products={margeArray}
                orders_groups={dataPed?.orders_groups}
                code_sale={id}></TableProductosServicios>
            </>
          )
        }
      : null,

    (userArea === 'compras' && dataPed?.orders_groups?.length > 0) ||
    (userArea === 'mesa_de_control' && dataPed?.orders_groups?.length > 0) ||
    (userArea === 'sgcmc' && dataPed?.orders_groups?.length > 0) ||
    (userDeparment === 'ventas' && dataPed?.orders_groups?.length > 0) ||
    (userDeparment === 'direccion' && dataPed?.orders_groups?.length > 0) ||
    (userRole === 'Super_Admin' && dataPed?.orders_groups?.length > 0) ||
    (decodedToken?.sub === 280 && dataPed?.orders_groups?.length > 0)
      ? {
          key: '3',
          label: 'TRAZABILIDAD DE PRODUCTO',
          children: (
            <>
              <TableTrazavilidadProducto
                setLoading={setLoading}
                loading={loading}
                products={margeArray}
                orders_groups={dataPed?.orders_groups}
                code_sale={id}></TableTrazavilidadProducto>
            </>
          )
        }
      : null,
    userDeparment === 'ventas' ||
    userDeparment === 'direccion' ||
    userArea === 'mesa_de_control' ||
    userArea === 'sgcmc' ||
    decodedToken?.sub === 280 ||
    userArea === 'almacen' ||
    userRole === 'Super_Admin'
      ? {
          key: '4',
          label: 'RECEPCIÓN DE INVENTARIO',
          children: (
            <>
              <TablaRecpcionInventario
                productRecepcion={dataPed?.orders_groups}
                id={id}
                loading={loading}
                setLoading={setLoading}></TablaRecpcionInventario>
            </>
          )
        }
      : null,
    userDeparment === 'ventas' ||
    userDeparment === 'direccion' ||
    userArea === 'calidad' ||
    userArea === 'mesa_de_control' ||
    userArea === 'sgcmc' ||
    decodedToken?.sub === 280 ||
    userRole === 'Super_Admin'
      ? {
          key: '5',
          label: 'INSPECCIÓN PRODUCTO',
          children: (
            <>
              <TablaInspeccion
                productInspeccion={dataPed?.orders_groups}
                loading={loading}
                setLoading={setLoading}></TablaInspeccion>
            </>
          )
        }
      : null
  ];

  const itemincidence = [
    {
      key: '1',

      label: 'INCIDENCIAS',
      children: (
        <>
          <SeccionHistorialIncidencias pedidoId={pedidoId} data={dataPed?.incidences} />
        </>
      )
    }
  ];

  let name =
    userDeparment === 'ventas'
      ? 'SEGUIMIENTO DE VENTAS'
      : userArea === 'compras'
      ? 'GESTIÓN DE COMPRAS'
      : userArea === 'almacen'
      ? 'GESTIÓN DE ALMACÉN'
      : userArea === 'mesa_de_control'
      ? 'GESTIÓN DE MESA DE CONTROL'
      : userArea === 'calidad'
      ? 'GESTIÓN DE CALIDAD'
      : userArea === 'direccion' ||
        userArea === 'ventas' ||
        userArea === 'sgcmc' ||
        decodedToken?.sub === 280 ||
        userRole === 'Super_Admin'
      ? 'SEGUIMIENTO DEL PEDIDO'
      : 'GESTION OC / OT';
  return (
    <>
      <Spin spinning={loading} tip="Loading">
        <div>
          <h1 className="mr-10">
            <ArrowLeftOutlined style={{ fontSize: '2rem' }} onClick={() => navigate(-1)} />
            {id}
          </h1>
          <Status2 status={dataPed?.status_sale === undefined ? [] : dataPed?.status_sale} />
        </div>

        <Form
          labelCol={{
            span: 12
          }}
          wrapperCol={{
            span: 11
          }}
          labelAlign="left"
          encType="multipart/form-data"
          layout="horizontal">
          <Card title="INFORMACIÓN GENERAL">
            <p className="ml-4 mr-4 mt-2 mb-2">
              <strong>Empresa:</strong>
              <br />
              <span>
                {dataPed?.additional_information?.Company?.trim() || 'No hay información'}
              </span>
            </p>
            <p className="ml-4 mr-4 mt-2 mb-2">
              <strong>Cliente:</strong> <br />
              <span>{dataPed?.more_information?.client_name?.trim() || 'No hay información'}</span>
            </p>
            <p className="ml-4 mr-4 mt-2 mb-2">
              <strong>Vendedor:</strong> <br />
              <span>
                {dataPed?.additional_information?.commercial_name?.trim() || 'No hay información'}
              </span>
            </p>
            <p className="ml-4 mr-4 mt-2 mb-2">
              <strong>Fecha confirmación:</strong> <br />
              <span>
                {parseDate(dataPed?.more_information?.planned_date?.trim(), 'short') ||
                  'No hay información'}
              </span>
            </p>

            <p className="ml-4 mr-4 mt-2 mb-2">
              <strong>Fecha de entrega: </strong> <br />
              <span>
                {parseDate(dataPed?.more_information?.commitment_date?.trim(), 'short') ||
                  'No hay información'}
              </span>
            </p>
          </Card>

          <Card title="RESUMEN" titleAlign="start">
            <p className="ml-5 mr-5 mt-2 mb-2">
              <strong>Órdenes compra:</strong>
              <br />
              <span>{ordenesDeCompra?.length}</span>
            </p>
            <p className="ml-5 mr-5 mt-2 mb-2">
              <strong>Órdenes trabajo:</strong> <br />
              <span>{ordenesDeTrabajo?.length}</span>
            </p>
          </Card>
        </Form>

        <Row>
          <Col
            span={24}
            md={
              userArea === 'mesa_de_control' ||
              userArea === 'sgcmc' ||
              userRole === 'Super_Admin' ||
              decodedToken?.sub === 280
                ? { span: 4, offset: 15 }
                : { span: 4, offset: 20 }
            }>
            <HistoryCheckList dataCheckList={dataPed?.check_list}></HistoryCheckList>
          </Col>

          {userArea === 'mesa_de_control' ||
          userArea === 'sgcmc' ||
          userRole === 'Super_Admin' ||
          decodedToken?.sub === 280 ? (
            <Col span={24} md={{ span: 4, offset: 1 }}>
              <ModalCheckList
                data={dataPed}
                setLoading={setLoading}
                loading={loading}></ModalCheckList>
            </Col>
          ) : null}
        </Row>

        <div className="mt-4 mb-4">
          <h2>{name}</h2>
          <Collapse className="mt-2">
            {items
              .filter((item) => item)
              .map((item) => (
                <Collapse.Panel header={item.label} key={item.key}>
                  {item.children}
                </Collapse.Panel>
              ))}
          </Collapse>
        </div>
        {decodedToken?.role?.name === 'equipo_ventas_méxico' ||
        decodedToken?.role?.name === 'equipo_ventas_pl' ||
        decodedToken?.role?.name === 'administrator' ? (
          <div className="mt--4" style={{ display: 'none' }}>
            <h2>INCIDENCIAS</h2>
            <Collapse className="mt-2">
              {itemincidence
                .filter((item) => item)
                .map((item) => (
                  <Collapse.Panel header={item.label} key={item.key}>
                    {item.children}
                  </Collapse.Panel>
                ))}
            </Collapse>
          </div>
        ) : null}
      </Spin>
    </>
  );
};
export default DetallePedido;
