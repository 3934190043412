import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Select, Table } from 'antd';
import Boton from '../Atoms/Boton';
import Swal from 'sweetalert2';
import clienteAxios from '../../Utils/clienteAxios';

const TableProductosServicios = ({ products, orders_groups, code_sale, loading, setLoading }) => {
  const [postData, setPostData] = useState([]);
  const [isGroupOrdersHandled, setIsGroupOrdersHandled] = useState(false);

  const arrayTransform = orders_groups?.map((item) => {
    let code_order_ot = item?.code_order_ot;
    let product_id_ot = item?.product_id_ot;
    let code_order_ot_array = JSON.parse(code_order_ot);
    let product_id_ot_array = JSON.parse(product_id_ot);
    return {
      ...item,
      code_order_ot: code_order_ot_array,
      product_id_ot: product_id_ot_array
    };
  });

  useEffect(() => {
    orders_groups?.length > 0 ? setPostData(arrayTransform) : setPostData([]);
  }, []);

  useEffect(() => {
    if (isGroupOrdersHandled) {
      postDataGroup();
      setIsGroupOrdersHandled(false);
    }
  }, [isGroupOrdersHandled, postData]);

  const ordenesDeCompra = products.filter((orden) => orden?.code_order?.substring(0, 2) === 'OC');

  const dataTablaOCFilter = ordenesDeCompra.filter(
    (orden) =>
      orden?.confirmation === 'Confirmado' ||
      (orden?.confirmation === 'Parcial' && orden?.idProductsOrders.includes(orden?.id))
  );

  const dataTableOC = dataTablaOCFilter.map((item, index) => ({
    key: index,
    reference: item?.code_order,
    description: item?.description,
    id: item?.id
  }));

  const dataTableOCArray = dataTableOC.map((item) => {
    return {
      code_order_oc: item?.reference,
      code_order_ot: [],
      product_id_ot: [],
      description: item?.description,
      product_id_oc: item?.id,
      code_sale: code_sale,
      planned_date: '2024-05-08'
    };
  });

  const ordenesDeTrabajo = products.filter((orden) => orden?.code_order?.substring(0, 2) === 'OT');

  const filterOt = ordenesDeTrabajo.filter(
    (orden) =>
      orden?.confirmation === 'Confirmado' ||
      (orden?.confirmation === 'Parcial' && orden?.idProductsOrders.includes(orden?.id))
  );

  const optionOt = filterOt.map((item, index) => ({
    label: item?.code_order + ' - ' + item?.description,
    value: `${item?.code_order},${item?.id}`,
    key: `${index},${item?.code_order}, ${item?.id}`
  }));

  const handlegroupOt = ({ code_order_OC, code_order_OT, product_id, description, code_sale }) => {
    const newProducts = [...postData];
    const postDataIndex = postData.findIndex((item) => item.product_id_oc === product_id);

    if (postDataIndex !== -1 && newProducts[postDataIndex]) {
      newProducts[postDataIndex] = {
        ...newProducts[postDataIndex],
        code_order_OT: code_order_OT,
        product_id_ot: code_order_OT.map((ot) => ot.split(',')[1]),
        code_order_OC: code_order_OC || newProducts[postDataIndex].code_order_OC,
        product_id: product_id || newProducts[postDataIndex].product_id,
        description: description || newProducts[postDataIndex].description,
        code_sale: code_sale || newProducts[postDataIndex].code_sale
      };
    } else {
      newProducts.push({
        code_order_OC: code_order_OC,
        code_order_OT: code_order_OT,
        product_id_ot: code_order_OT.map((ot) => ot.split(',')[1]),
        description: description,
        product_id: product_id,
        code_sale: code_sale
      });
    }

    let normalizedData = newProducts.map((item) => {
      let splitCode_Order_OT = item?.code_order_OT?.map((ot) => ot.split(',')[0]);
      let splitIdProduct_OT = item?.code_order_OT?.map((ot) => ot.split(',')[1]);
      const uniQueArray = [...new Set(splitCode_Order_OT)];
      let newItem = {
        code_order_oc:
          item.code_order_OC || item.code_order || item?.reference || item?.code_order_oc,
        code_order_ot:
          item?.code_order_OT?.length === 0
            ? []
            : uniQueArray?.length === 0
            ? item?.code_order_ot || []
            : uniQueArray || [],
        product_id_ot:
          splitIdProduct_OT === undefined ? item?.product_id_ot || [] : splitIdProduct_OT || [],
        description: item.description || description,
        product_id_oc: item.product_id || item?.product_id_oc || item.id,
        code_sale: item.code_sale || code_sale,
        planned_date: '2024-05-08'
      };
      return newItem;
    });
    setPostData(normalizedData);
  };

  const columnsTable = [
    {
      title: 'DESCRIPCIÓN',
      dataIndex: 'description',
      key: 'description',
      fixed: 'left'
    },
    {
      title: 'REFERENCIA',
      dataIndex: 'reference',
      key: 'reference'
    },
    {
      title: 'AGRUPAR OT',
      dataIndex: 'agruparot',
      key: 'agruparot',
      render: (text, record, index) => (
        <>
          <Select
            key={index}
            mode="multiple"
            options={optionOt}
            defaultValue={arrayTransform
              ?.find((item) => item?.product_id_oc === record?.id)
              ?.code_order_ot.map(
                (ot, index) =>
                  `${ot},${
                    arrayTransform?.find((item) => item?.product_id_oc === record?.id)
                      ?.product_id_ot[index]
                  }`
              )}
            onChange={(value) =>
              handlegroupOt({
                code_order_OC: record?.reference,
                description: record?.description,
                code_order_OT: value,
                code_order_index: index,
                code_sale: code_sale,
                product_id: record?.id
              })
            }
            style={{ width: 900 }}
            placeholder="Agrupar Servicios ( OT )"
          />
        </>
      )
    }
  ];

  const handleGroupOrders = () => {
    const mergeArrays = () => {
      const postDataMap = new Map(postData.map((item) => [item.product_id_oc, item]));
      const resultArray = dataTableOCArray.map((item) =>
        postDataMap.has(item.product_id_oc) ? postDataMap.get(item.product_id_oc) : item
      );
      return resultArray;
    };
    const result = mergeArrays();
    setPostData(result);
    setIsGroupOrdersHandled(true);
  };

  const postDataGroup = () => {
    Swal.fire({
      title: '¿Estás seguro de sincronizar las órdenes?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sincronizar'
    }).then((result) => {
      if (result.isConfirmed) {
        clienteAxios;
        orders_groups?.length > 0
          ? clienteAxios
              .post(`pedidos-orders-groups/update/${code_sale}`, { ordenes: postData })
              .then((response) => {
                console.log(response);
                setLoading(!loading);
                Swal.fire(
                  'Sincronizado!',
                  'Las órdenes se han actualizado correctamente.',
                  'success'
                );
              })
              .catch((error) => {
                console.error(error);
                setLoading(false);
                Swal.fire({
                  title: 'Error',
                  text: error?.response?.data?.msg,
                  icon: 'error',
                  confirmButtonText: 'Aceptar'
                });
              })
          : clienteAxios
              .post('pedidos-orders-groups', { ordenes: postData })
              .then((response) => {
                console.log(response);
                setLoading(!loading);
                Swal.fire(
                  'Sincronizado!',
                  'Las órdenes  se han sincronizado correctamente.',
                  'success'
                );
              })
              .catch((error) => {
                console.error(error);
                setLoading(false);
                Swal.fire({
                  title: 'Error',
                  text: error?.response?.data?.msg,
                  icon: 'error',
                  confirmButtonText: 'Aceptar'
                });
              });
      }
    });
  };

  return (
    <>
      {dataTablaOCFilter?.length > 0 ? (
        <div className="flex justify-content-end mt-3">
          <Boton type="primary" onClick={handleGroupOrders}>
            {orders_groups?.length > 0 ? 'Actualizar Ordenes' : 'Sincronizar Ordenes'}
          </Boton>
        </div>
      ) : null}
      <Table
        loading={loading}
        className="mt-3 mb-3"
        columns={columnsTable}
        dataSource={dataTableOC}
        pagination={false}></Table>
    </>
  );
};

TableProductosServicios.propTypes = {
  products: PropTypes.array,
  orders_groups: PropTypes.array,
  code_sale: PropTypes.string,
  loading: PropTypes.bool,
  setLoading: PropTypes.func
};

export default TableProductosServicios;
