import { Input, Space, Table } from 'antd';
import React, { useState, useRef } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import localeObjectTable from '../../Utils/localeObjectTable';
import PropTypes from 'prop-types';
import currencyFormatter from '../../Utils/currencyFormatter';
import { DatePicker } from 'antd';
import moment from 'moment';
import es from 'antd/lib/date-picker/locale/es_ES';
import Boton from '../Atoms/Boton';

const TablaHistorialIngreso = ({ data, loading }) => {
  const { RangePicker } = DatePicker;
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: '0 8px 8px 8px'
        }}
        onKeyDown={(e) => e.stopPropagation()}>
        <Boton
          type="link"
          size="small"
          onClick={() => {
            close();
          }}
          className="button-close-search-filter-table">
          X
        </Boton>
        <Input
          ref={searchInput}
          placeholder={`Buscar`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block'
          }}
        />
        <Space>
          <Boton
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small">
            Buscar
          </Boton>
          <Boton onClick={() => clearFilters && handleReset(clearFilters)} size="small" danger>
            Limpiar
          </Boton>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      )
  });

  const tablaReporte = [
    {
      title: 'INGRESADO POR',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name'),
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'FECHA DE INGRESO',
      dataIndex: 'created_date',
      key: 'created_date',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
        <div
          style={{
            padding: '0 8px 8px 8px'
          }}
          onKeyDown={(e) => e.stopPropagation()}>
          <Boton
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
            className="button-close-search-filter-table">
            X
          </Boton>
          <RangePicker
            locale={es}
            placeholder={['Fecha inicial', 'Fecha final']}
            value={selectedKeys[0]}
            onChange={(dates) => {
              setSelectedKeys([dates]);
            }}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Boton
              type="primary"
              onClick={() => {
                confirm();
              }}
              icon={<SearchOutlined />}
              size="small">
              Buscar
            </Boton>
            <Boton onClick={clearFilters} size="small" danger>
              Limpiar
            </Boton>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{
            color: filtered ? '#1890ff' : undefined
          }}
        />
      ),
      onFilter: (value, record) => {
        if (!value) return true;
        const start = value[0].startOf('day').toDate(); // Convertir a objeto Date
        const end = value[1].endOf('day').toDate(); // Convertir a objeto Date
        const created = moment(record.created_date, 'DD-MM-YYYY').toDate(); // Convertir a objeto Date
        return created >= start && created <= end;
      },
      sorter: (a, b) => moment(a.created_date, 'DD-MM-YYYY') - moment(b.created_date, 'DD-MM-YYYY'),
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'TOTAL',
      dataIndex: 'total',
      key: 'total',
      ...getColumnSearchProps('total'),
      sorter: (a, b) => a.total - b.total,
      sortDirections: ['ascend', 'descend'],
      render: (text) => currencyFormatter(text)
    }
  ];

  const newDataSOL = data?.map((item, index) => {
    return {
      key: index,
      name: item?.name,
      created_date: item?.created_date,
      total: item.total
    };
  });

  return (
    <>
      <Table
        loading={loading}
        columns={tablaReporte}
        dataSource={newDataSOL}
        pagination={{
          position: ['bottomCenter'],
          defaultPageSize: 10,
          pageSizeOptions: [20, 40, 60, 80, 100],
          total: data.total,
          showTotal: (total, range) =>
            `Mostrando ingresos del ${range[0]} al ${range[1]} de un total de ${total}`
        }}
        className="mt-4 mb-4"
        locale={localeObjectTable}
        scroll={{
          x: true
        }}
      />
    </>
  );
};

TablaHistorialIngreso.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool
};

export default TablaHistorialIngreso;
