import React, { useEffect, useState } from 'react';
import ReusableModal from '../Atoms/Modal';
import PropTypes from 'prop-types';
import clienteAxios from '../../Utils/clienteAxios';
import { Col, Row, Table, Tag } from 'antd';
import parseDate from '../../Utils/parseDate';
import moment from 'moment';

const ModalHistoryRecep = ({ isOpen, setModalHistoryRecepcion, dataHistoryRecep }) => {
  const [informationRecep, setInformationRecep] = useState([]);
  const [loading, setLoading] = useState('');

  useEffect(() => {
    if (Object.keys(dataHistoryRecep).length !== 0) {
      setLoading(true);
      clienteAxios
        .get(`history/confirmation/route/product/${dataHistoryRecep?.idProdutc}`)
        .then((response) => {
          setInformationRecep(response?.data?.HistoryConfirmationRoutes?.reverse());
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [isOpen]);

  const onCancel = () => {
    setModalHistoryRecepcion(false);
  };

  const columsHistory = [
    {
      title: 'ALMACÉN',
      dataIndex: 'almacen',
      key: 'almacen'
    },
    {
      title: 'TIPO',
      dataIndex: 'tipo',
      key: 'tipo'
    },
    {
      title: 'FECHA DE RECEPCIÓN',
      dataIndex: 'fecha_recepcion',
      key: 'fecha_recepcion'
    }
  ];

  const data = informationRecep.map((item, index) => ({
    key: index,
    almacen: item?.destination,
    tipo: (
      <>
        <Tag color={item?.reception_type === 'Total' ? 'green' : 'orange'}>
          {item?.reception_type}
        </Tag>
      </>
    ),
    fecha_recepcion: (
      <>
        <Row>
          <Col xs={24} md={12}>
            {parseDate(item?.created_at, 'short')}
          </Col>
          <Col xs={24} md={12}>
            {moment(item?.created_at).format('LT  ')}
          </Col>
        </Row>
      </>
    )
  }));

  return (
    <>
      <ReusableModal title="HISTORIAL DE RECEPCIONES" open={isOpen} onCancel={onCancel} width={700}>
        <Table
          columns={columsHistory}
          dataSource={data}
          loading={loading}
          pagination={false}></Table>
      </ReusableModal>
    </>
  );
};

ModalHistoryRecep.propTypes = {
  isOpen: PropTypes.bool,
  setModalHistoryRecepcion: PropTypes.func,
  dataHistoryRecep: PropTypes.object
};

export default ModalHistoryRecep;
