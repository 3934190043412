import React, { useEffect, useState, useLayoutEffect } from 'react';
import TablaHistorialGastado from '../Components/Organisms/TablaHistorialGastado';
import { ArrowLeftOutlined, FileExcelOutlined } from '@ant-design/icons';
import clienteAxios from '../Utils/clienteAxios';
import ReactHtmlTableToExcel from 'react-html-table-to-excel';
import { useNavigate } from 'react-router-dom';
import { Alert } from 'antd';

const HistorialGastado = () => {
  const [egressHistory, setEgressHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useLayoutEffect(() => {
    document.querySelector('.ant-table-content table').id = 'table-to-xls';
  }, []);

  useEffect(() => {
    clienteAxios
      .get(`/caja-chica/information/history`)
      .then((response) => {
        let data = response.data.MonthlyExpense;
        data.reverse();
        setEgressHistory(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <>
      <div className="title-content-between">
        <h1 className="mb-2 mr-5">
          <ArrowLeftOutlined className="arrow-size" onClick={() => navigate(-1)} />
          HISTORIAL DE PRESUPUESTO GASTADO
        </h1>
        <div className="mb-2 flex justify-content-end">
          <ReactHtmlTableToExcel
            id="test-table-xls-button"
            className="download-table-xls-button"
            table="table-to-xls"
            filename="Historial Gastado"
            sheet="Historial Gastado"
            buttonText={
              <>
                <FileExcelOutlined className="mr-2" />
                Exportar en Excel
              </>
            }
          />
        </div>
      </div>

      <div className="mt-1">
        <Alert
          className="width-100"
          message="En este apartado solo se muestra las solicitudes de presupuesto que se encuentran en estado de 'Recibido'."
          type="info"
          showIcon
          closable
        />
      </div>

      <div className="flex-row flex-wrap mt-1">
        <TablaHistorialGastado data={egressHistory} loading={loading} />
      </div>
    </>
  );
};

export default HistorialGastado;
